<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Primerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <div class="section-title">
        <div class="text-small light">Údaje pre výpočet priemerného mesačného príjmu zadávajte za posledné ukončené účtovné obdobie. Viac o tom ako čítať daňové priznanie nájdete <a class="link" href="https://www.bankari.sk/ako-na-to/hypoteka-pre-zivnostnikov-citanie-danoveho-priznania" target="_blank">TU</a></div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'zdanenie_minuly_rok')"
          :id="'zdanenie_minuly_rok'"
          :init-value="form.zdanenie_minuly_rok"
          :options="booleanForm"
          normal
          class="required"
          :name="'Bol príjem zdanený?'"
          required
        />
      </div>
      <div class="input-group">
        <label for="trzby" class="field-label"
        >Príjmy (riadok 39 daňového priznania)</label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.minulorocny_obrat"
          type="number"
          step="0.01"
          class="text-input w-input required"
          name="trzby"
          data-name="trzby"
          required
          id="trzby"
        />
      </div>
      <div class="dropdown-input-custom">
        <input
          type="text"
          v-model="form.pausalne_vydavky"
          style="opacity: 0; position: absolute"
          class="required"
          required
        />
        <dropdown-select
          @select="setDropdownValue($event, 'pausalne_vydavky')"
          :id="'pausalne_vydavky'"
          :init-value="form.pausalne_vydavky"
          :options="lastYearExpenses" 
          class="required"
          normal
          :name="'Výdavky? (paušálne / reálne, nájdete na str. 4)'"
        />
      </div>
      <div v-if="!form.pausalne_vydavky" class="input-group">
        <label for="vydavky" class="field-label"
        >Výdavky (riadok 40 daňového priznania)
        </label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.minulorocne_vydavky"
          type="number"
          step="0.01"
          class="text-input w-input required"
          name="vydavky"
          data-name="vydavky"
          required
          id="vydavky"
        />
      </div>
      <div class="dropdown-input-custom">
        <input
          type="text"
          v-model="form.lastYearExpenses"
          style="opacity: 0; position: absolute"
          class="required"
          required
        />
        <dropdown-select
          @select="setDropdownValue($event, 'lastYearExpenses')"
          :id="'tradesman_vydavky'"
          :init-value="form.lastYearExpenses"
          :options="insuranceLastYear"
          normal
          :name="'Ako chcete zadať poistné'"
        />
      </div>
      <div class="input-group" v-if="form.lastYearExpenses !== 'other'">
        <label for="poistne" class="field-label"
        >{{ form.lastYearExpenses ? "Mesačné poistné" : "Zaplatené poistné (riadok 11 daňového priznania)" }}
        </label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.szco_rocne_poistne"
          type="number"
          step="0.01"
          class="text-input w-input"
          name="poistne"
          data-name="poistne"
          id="poistne"
        />
      </div>
      <div class="input-group">
        <label for="zakladDane" class="field-label"
        >Základ dane (riadok 45 daňového priznania)</label>
        <input
          v-model.number="taxBase"
          type="number"
          step="0.01"
          class="text-input w-input"
          name="zakladDane"
          data-name="zakladDane"
          id="zakladDane"
        />
      </div>
      <div class="input-group">
        <label for="danZPrijmu" class="field-label"
        >Daň (riadok 116 daňového priznania)</label>
        <input
          v-model.number="form.incomeTax"
          type="number"
          step="0.01"
          class="text-input w-input"
          name="danZPrijmu"
          data-name="danZPrijmu"
          id="danZPrijmu"
        />
      </div>
      <div class="section-title">
        <div class="text-small light"> Údaje o živnosti overíte na
            <a class="link" href="https://www.zrsr.sk/default.aspx" target="_blank">zrsr.sk</a>
        </div> 
      </div>
      <div
        class="input-group"
      >
        <label for="Typ-pr-jmu-2" class="field-label"
        >IČO
          <span class="pop-300">(údaje sa doplnia automaticky)</span></label
        ><input
        @change="initializeEmployer"
        v-model.lazy="ico"
        type="text"
        class="text-input w-input"
        minlength="8"
        name="icoZamestnavatel"
        data-name="Typ Pr Jmu 2"
        placeholder=""
        id="Typ-pr-jmu-2"
      />
      </div>
      <div class="input-group">
        <label class="field-label">Sídlo</label
        ><input
        v-model="employerDetail.slovensko_digital_response.formatted_address"
        readonly
        type="text"
        class="input-color-disabled text-input w-input"
        maxlength="256"
        name="sidlo"
        data-name="sidlo"
        placeholder=""
        id="sidlo"
      />
      </div>
      <div
        class="input-group"
      >
        <label for="podnikamOd" class="field-label"
        >Podnikám <strong>od</strong></label
        ><input
        v-model="form.podnikanieOd"
        type="date"
        :max="getTodayDate()"
        class="text-input w-input date-input required"
        maxlength="256"
        name="podnikamOd"
        data-name="podnikamOd"
        id="podnikamOd"
        required
      />
      </div>
      <div class="dropdown-input-custom rest-input required">
        <dropdown-select
          @select="setDropdownValue($event, 'odvetvie')"
          :id="'tradesman_odvetvie'"
          :init-value="form.odvetvie"
          :options="formTemplate.zamestnavatel_odvetvie.choices"
          normal
          :name="'Odvetvie'"
          required
        />
      </div>
      <div class="dropdown-input-custom rest-input" style="margin-top: 17px">
        <dropdown-select
          @select="setDropdownValue($event, 'komora')"
          :id="'tradesman_komora'"
          :init-value="form.komora"
          :options="formTemplate.komora.choices"
          normal
          :name="'Komora'"
        />
      </div>
      <div class="dropdown-input-custom rest-input" style="margin-top: 17px">
        <dropdown-select
          @select="
            setDropdownValue($event, 'prechod_zivnost_tpp_poslednych_6m')
          "
          :id="'tradesman_prechod_tpp'"
          :init-value="form.prechod_zivnost_tpp_poslednych_6m"
          :options="[
            {
              display_name: 'áno',
              value: true
            },
            {
              display_name: 'nie',
              value: false
            },
          ]"
          normal
          :name="'Prechod zo živnosti na zamestnanie'"
        />
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import {getEmployerByIco, getEmployerByID, getSzcoDTI} from "@/services/brokerService";

export default {
  name: "TradesmanForm",
  components: {DropdownSelect},
  props: {
    income: [Object, null],
    formTemplate: Object,
  },
  watch: {
    "form.komora"(newKomora) {
      if (newKomora) this.form.odvetvie = null;
    },
    "form.odvetvie"(newOdvetvie) {
      if (newOdvetvie) this.form.komora = null;
    },
    income(updatedIncome) {
      if (typeof updatedIncome.zamestnavatel === "object") {
        this.employerDetail = updatedIncome.zamestnavatel;
        this.ico = updatedIncome.zamestnavatel.ico;
      }
    }
  },
  async created () {
    if (this.income) {
      this.form.podnikanieOd = this.income.zamestnanie_od;
      this.taxBase = this.income.zaklad_dane_z_prijmu;
      this.form.zdanenie_minuly_rok = this.income.zdanenie_minuly_rok;
      this.form.odvetvie = this.income.zamestnavatel_odvetvie;
      this.form.pausalne_vydavky = this.income.pausalne_vydavky;
      this.form.szco_rocne_poistne = this.income.szco_rocne_poistne;
      this.form.minulorocne_vydavky = this.income.minulorocne_vydavky;
      this.form.minulorocny_obrat = this.income.minulorocny_obrat;
      this.form.komora = this.income.komora;
      this.form.incomeTax = this.income.dan_z_prijmu;
      this.form.prechod_zivnost_tpp_poslednych_6m =
        this.income.prechod_zivnost_tpp_poslednych_6m;
      if (this.income.szco_rocne_poistne === 0) {
        this.form.lastYearExpenses = "other";
      }
      if (typeof this.income.zamestnavatel === "object") {
        this.ico = this.income.zamestnavatel.ico;
        this.employerDetail = this.income.zamestnavatel;
      } else if (typeof this.income.zamestnavatel === "number") {
        await this.initializeEmployerById(this.income.zamestnavatel);
        this.ico = this.employerDetail.ico;
      }
    }
    this.showEstimatedDtiToUser();
  },
  data: () => ({
    booleanForm: [
      {
        display_name: "áno",
        value: true,
      },
      {
        display_name: "nie",
        value: false,
      },
    ],
    dtiLoading: false,
    ico: null,
    estimatedDti: null,
    taxBase: null,
    insuranceLastYear: [
      {
        display_name: "výpočtom",
        value: true,
      },
      {
        display_name: "z priznania",
        value: false,
      },
      {
        display_name: "neviem",
        value: "other",
      },
    ],
    form: {
      zdanenie_minuly_rok: null,
      incomeTax: null,
      lastYearExpenses: false,
      pausalne_vydavky: true,
      podnikanieOd: null,
      szco_rocne_poistne: null,
      minulorocne_vydavky: null,
      minulorocny_obrat: null,
      komora: null,
      odvetvie: null,
      prechod_zivnost_tpp_poslednych_6m: false,
    },
    employerDetail: {
      slovensko_digital_response: {
        formatted_address: null,
      },
    },
    lastYearExpenses: [
      {
        display_name: "paušálne výdavky",
        value: true,
      },
      {
        display_name: "reálne",
        value: false,
      }
    ],
  }),
  methods: {
    async initializeEmployerById(id) {
      this.employerDetail = await getEmployerByID(id);
      this.form.podnikanieOd = this.employerDetail.datum_zalozenia.substr(0, 10);
    },
    pickMoIncome () {
      if (!this.estimatedDti) return null;
      if (this.estimatedDti.extra.dti_z_obratu.value === this.estimatedDti.value) {
        return this.estimatedDti.extra.dti_z_obratu.extra.prijem_mo;
      } else if (this.estimatedDti.extra.dti_zo_zakl_dane.value === this.estimatedDti.value) {
        return this.estimatedDti.extra.dti_zo_zakl_dane.extra.prijem_mo;
      }
      return null;
    },
    // setExpense (pausalne, minulorocne) {
    //   if (pausalne) this.form.vydavky = true;
    //   else {
    //     this.form.vydavky = minulorocne;
    //     this.lastYearExpenses.push({
    //       display_name: minulorocne,
    //       value: minulorocne,
    //     });
    //   }ß
    // },
    async showEstimatedDtiToUser () {
      this.dtiLoading = true;
      const incomeData = {
        trzby: this.form.minulorocny_obrat,
        rocne_poistne: this.form.lastYearExpenses ? this.form.szco_rocne_poistne * 12 : this.form.szco_rocne_poistne,
      };
      if (this.form.pausalne_vydavky) {
        incomeData['pausalne_vydavky'] = this.form.pausalne_vydavky;
      } else {
        incomeData['vydavky'] = this.form.minulorocne_vydavky;
      }
      this.estimatedDti = null;
      if (this.checkValue(incomeData.trzby) && this.checkValue(incomeData.rocne_poistne) && (this.checkValue(incomeData.pausalne_vydavky) || this.checkValue(incomeData.vydavky))) {
        const dtiData = await getSzcoDTI(incomeData);
        this.pickHighestDti(dtiData);
      }
      this.dtiLoading = false;
    },
    checkValue(value) {
      return !(value === undefined || value === null || value === "");
    },
    pickHighestDti (dtiData) {
      let valueDti = 0;
      for (const value of Object.entries(dtiData)) {
        for (const valueElement of value) {
          if (valueDti < valueElement.value) {
            valueDti = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
    },
    async initializeEmployer () {
      if (!this.ico) {
        this.employerDetail = {
          slovensko_digital_response: {
            formatted_address: null,
          },
        };
      } else {
        try {
          getEmployerByIco({ico: this.ico}).then((employer) => {
            this.employerDetail = employer;
            this.form.podnikanieOd = this.employerDetail.datum_zalozenia.substr(0, 10);
          });
        } catch (e) {
          this.employerDetail = {
            slovensko_digital_response: {
              formatted_address: null,
            },
          };
          this.$store.commit("appState/setSuccessMessage", {
            text: "Zamestnávateľ podľa iča nebol nájdený",
            type: "error"
          });
        }
      }
    },
    getTodayDate () {
      const today = new Date();
      const dd = today.getDate();
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
      if (dropdownName === "lastYearExpenses" && value === "other") {
        this.form.szco_rocne_poistne = 0;
      }
      if (!(["lastYearExpenses", "pausalne_vydavky"].includes(dropdownName))) return;
      this.showEstimatedDtiToUser();
    },
    saveIncome () {
      const incomeObject = {
        druh_prijmu_szco: "zivnost",
        typ: "hlavny",
        druh: "prijem_zivnostnika",
        szco_rocne_poistne: this.form.lastYearExpenses === "other" ? 0 : this.form.lastYearExpenses ? this.form.szco_rocne_poistne * 12 : this.form.szco_rocne_poistne,
        minulorocne_vydavky: this.form.pausalne_vydavky ? null : this.form.minulorocne_vydavky,
        pausalne_vydavky: this.form.pausalne_vydavky,
        zdanenie_minuly_rok: this.form.zdanenie_minuly_rok,
        minulorocny_obrat: this.form.minulorocny_obrat,
        komora: this.form.komora,
        zamestnanie_od: this.form.podnikanieOd,
        zamestnavatel_odvetvie: this.form.odvetvie,
        dan_z_prijmu: this.form.incomeTax,
        prechod_zivnost_tpp_poslednych_6m:
        this.form.prechod_zivnost_tpp_poslednych_6m,
        zamestnavatel: this.employerDetail.id,
        zaklad_dane_z_prijmu: this.taxBase,
      };
      if (this.income) {
        incomeObject.id = this.income.id;
      }
      // console.log(incomeObject);
      this.$emit("income-saved", {incomeInfo: incomeObject});
    },
    // getExpense (value, type) {
    //   if (type === "pausalne" && typeof value === "boolean") {
    //     return value;
    //   } else if (type === "pausalne" && typeof value !== "boolean") {
    //     return false
    //   } else if (type === "minulorocne" && typeof value === "number") {
    //     return value;
    //   }
    //   return null;
    // },
    translateDateFromDbValue (dateToTransform) {
      if (!dateToTransform) return null;
      let dateArray = dateToTransform.split("-");
      dateArray.pop();
      return dateArray.join("-");
    },
  },
};
</script>

<style scoped lang="scss">
.error-text {
  font-size: 11px;
}

</style>
