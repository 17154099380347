import { render, staticRenderFns } from "./csob_prijmy.vue?vue&type=template&id=d4a15ef2&scoped=true&"
import script from "./csob_prijmy.vue?vue&type=script&lang=js&"
export * from "./csob_prijmy.vue?vue&type=script&lang=js&"
import style0 from "./csob_prijmy.vue?vue&type=style&index=0&id=d4a15ef2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4a15ef2",
  null
  
)

export default component.exports