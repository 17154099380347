import {getMortgageById, getMortgages, patchMortgage, postMortgage} from "@/services/brokerService";

const deepEqual = require("deep-equal");

export default {
  namespaced: true,
  state: {
    mortgageRefresh: true,
    filters: {
      created__gte: null,
      created__lt: null,
      page_size: 10,
      financnik: null,
      search: null,
    },
    pages: {
      zrusena: 1,
      vyber_riesenia: 1,
      podpis_ziadosti: 1,
      kompletizacia_podkladov: 1,
      schvalovanie: 1,
      servis: 1,
    },
    mortgages: {
      zrusena: [],
      vyber_riesenia: [],
      podpis_ziadosti: [],
      kompletizacia_podkladov: [],
      schvalovanie: [],
      servis: [],
    },
    mortgagesCount: {
      zrusena: 0,
      vyber_riesenia: 0,
      podpis_ziadosti: 0,
      kompletizacia_podkladov: 0,
      schvalovanie: 0,
      servis: 0,
    },
  },
  getters: {
    getMortgages: (state) => {
      return state.mortgages;
    },
    getMortgagesCount: (state) => {
      return state.mortgagesCount;
    },
  },
  mutations: {
    setRefreshMortgageAuto: (state, value) => {
      state.mortgageRefresh = value;
    },
    registerNewMortgage: (state, mortgage) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      mortgagesUpdated[mortgage.stage].unshift(mortgage);
      state.mortgages = mortgagesUpdated;
    },
    attachNextPageMortgages: (state, mortgageInfo) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      let mortgagesStageUpdated = mortgagesUpdated[mortgageInfo.stage];
      mortgagesStageUpdated = [...mortgagesStageUpdated, ...mortgageInfo.results];
      mortgagesUpdated[mortgageInfo.stage] = mortgagesStageUpdated;
      state.mortgages = mortgagesUpdated;
    },
    updateMortgage: (state, mortgageInfo) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      const mortgagesStageUpdated = mortgagesUpdated[mortgageInfo.stage];
      const index = mortgagesStageUpdated.map((mortgage) => {
        return mortgage.id
      }).indexOf(mortgageInfo.mortgage.id);
      mortgagesStageUpdated[index] = mortgageInfo.mortgage;
      state.mortgages = mortgagesUpdated;
    },
    setMortgages: (state, mortgages) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      mortgagesUpdated[mortgages.stage] = mortgages.results;
      state.mortgages = mortgagesUpdated;
    },
    switchMortgageBetweenStages: (state, mortgage) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      const oldMortgageStage = mortgagesUpdated[mortgage.stage_old];
      const oldIndex = oldMortgageStage.map((m) => m.id).indexOf(mortgage.id);
      oldMortgageStage.splice(oldIndex, 1);
      mortgagesUpdated[mortgage.stage].unshift(mortgage);
      state.mortgages = mortgagesUpdated;
    },
    pushMortgage: (state, mortgage) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      mortgagesUpdated[mortgage.stage].push(mortgage);
      state.mortgages = mortgagesUpdated;
    },
    removeMortgage: (state, mortgage) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      const mortgagesCountUpdated = JSON.parse(JSON.stringify(state.mortgagesCount));
      const indexOfRemoved = mortgagesUpdated[mortgage.stage].map(e => e.id).indexOf(mortgage.id);
      mortgagesUpdated[mortgage.stage].splice(indexOfRemoved, 1);
      mortgagesCountUpdated[mortgage.stage] -= 1;
      state.mortgages = mortgagesUpdated;
      state.mortgagesCount = mortgagesCountUpdated;
    },
    moveMortgageFromStageToStage: (state, mortgage) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgages));
      const mortgagesCountUpdated = JSON.parse(JSON.stringify(state.mortgagesCount));
      mortgagesCountUpdated[mortgage.stage] += 1;
      mortgagesUpdated[mortgage.stage].push(mortgage);
      const indexOfMoved = mortgagesUpdated[mortgage.oldStage].map(e => e.id).indexOf(mortgage.id);
      mortgagesCountUpdated[mortgage.oldStage] -= 1;
      mortgagesUpdated[mortgage.oldStage].splice(indexOfMoved, 1);
      delete mortgage.oldStage;
      state.mortgagesCount = mortgagesCountUpdated;
      state.mortgages = mortgagesUpdated;
    },
    setMortgagesCounts: (state, counts) => {
      state.mortgagesCount = counts;
    },
    setMortgagesCountByStage: (state, mortgages) => {
      const mortgagesUpdated = JSON.parse(JSON.stringify(state.mortgagesCount));
      mortgagesUpdated[mortgages.stage] = mortgages.count;
      state.mortgagesCount = mortgagesUpdated;
    },
    resetMortgagesFilters: (state) => {
      state.filter = {
        created__gte: null,
        created__lt: null,
        page_size: 10,
        financnik: null,
        search: null,
        is_active: true,
      };
      state.pages = {
        vyber_riesenia: 1,
        podpis_ziadosti: 1,
        kompletizacia_podkladov: 1,
        schvalovanie: 1,
        servis: 1,
      };
    },
    setMortgagesFilter: (state, data) => {
      state.filters[data.key] = data.value;
    },
    setStagePages: (state, pagesInfo) => {
      state.pages[pagesInfo.stage] = pagesInfo.pages;
    }
  },
  actions: {
    initializeMortgages: async ({commit, state}) => {
      state.filters.search = null;
      for (const [stage, pages] of Object.entries(state.pages)) {
        const mortgageResponse = await getMortgages({...state.filters, page_size: pages * 10, stage, page: 1, expand:"coapplicant,applicant.client_profile"});
        let mortgages = mortgageResponse.results;
        const mortgagesCount = mortgageResponse.count;
        mortgages = reduceMortgages(mortgages);
        commit("setMortgages", {stage, results: mortgages});
        commit("setMortgagesCountByStage", {stage: stage, count: mortgagesCount});
      }
    },
    fetchMortgages: async ({commit, state}, stopReload = true) => {
      if (!state.mortgageRefresh) return;
      for (const [stage, pages] of Object.entries(state.pages)) {
        const mortgageResponse = await getMortgages({...state.filters, page_size: pages * 10, stage, page: 1, expand:"coapplicant,applicant.client_profile"});
        let mortgages = mortgageResponse.results;
        const mortgagesCount = mortgageResponse.count;
        mortgages = reduceMortgages(mortgages);
        if (!deepEqual(mortgages, state.mortgages, true)) {
          commit("setMortgages", {stage, results: mortgages});
          commit("setMortgagesCountByStage", {stage: stage, count: mortgagesCount});
        }
      }
      if (stopReload) commit("appState/setLoadingPage", false, {root: true});
    },
    nextPageMortgages: async ({commit, state}, stage) => {
      let pages = state.pages[stage];
      pages++;
      commit("setStagePages", {stage, pages});
      const mortgageResponse = await getMortgages({...state.filters, page_size: 10, stage, page: pages, expand:"coapplicant,applicant.client_profile"});
      let mortgage = mortgageResponse.results;
      const mortgagesCount = mortgageResponse.count;
      mortgage = reduceMortgages([mortgage])[0];
      commit("attachNextPageMortgages", {stage: stage, results: mortgage});
      commit("setMortgagesCountByStage", {stage: stage, count: mortgagesCount});
    },
    moveMortgageToDifferentStage: async  ({dispatch, commit}, mortgageInfo) => {
      commit("setRefreshMortgageAuto", false);
      const verb = mortgageInfo.stage_old !== "zrusena" ? "do" : "z";
      try {
        commit("switchMortgageBetweenStages", mortgageInfo);
        commit("appState/setSuccessMessage", {
          text: `Hypotéka bola úspešne prenesená ${verb} prerušených`,
          type: "success"
        }, {root: true});
        await patchMortgage({...mortgageInfo, applicant: mortgageInfo.applicant.id, coapplicant: mortgageInfo.coapplicant?.id}, mortgageInfo.id);
      } catch (e) {
        commit("switchMortgageBetweenStages", {...mortgageInfo, stage_old: mortgageInfo.stage, stage: mortgageInfo.stage_old});
        commit("appState/setSuccessMessage", {
          text: `Hypotéku sa nepodarilo preniesť ${verb} prerušených`,
          type: "error"
        }, {root: true});
      }
      commit("setRefreshMortgageAuto", true);
    },
    fetchMortgage: async ({commit}, mortgageInfo) => {
      commit("setRefreshMortgageAuto", false);
      let mortgage = await getMortgageById(mortgageInfo.id, "coapplicant,applicant.client_profile");
      mortgage = reduceMortgages([mortgage])[0];
      commit("updateMortgage", {mortgage, stage: mortgageInfo.stage});
      commit("setRefreshMortgageAuto", true);
    },
    postMortgage: async ({dispatch, commit}, mortgageInfo) => {
      commit("setRefreshMortgageAuto", false);
      commit("appState/setLoadingPage", true, {root: true});
      let mortgage = await postMortgage(mortgageInfo);
      mortgage = await getMortgageById(mortgage.id, "coapplicant,applicant.client_profile");
      commit("registerNewMortgage", mortgage);
      commit("appState/setLoadingPage", false, {root: true});
      commit("appState/setSuccessMessage", {
        text: "Hypotéka bola úspešne vytvorená",
        type: "success"
      }, {root: true});
      commit("setRefreshMortgageAuto", true);
    },
  },
};

function reduceMortgages (mortgages) {
  if (mortgages.length === 0) return mortgages;
  const newMortgages = JSON.parse(JSON.stringify(mortgages));
  newMortgages.forEach((mortgage) => {
    const applicantDetailReduced = {
      id: mortgage.applicant_detail?.id,
      first_name: mortgage.applicant_detail?.first_name,
      last_name: mortgage.applicant_detail?.last_name,
      email: mortgage.applicant_detail?.email,
      financnik: mortgage.applicant_detail?.financnik,
      client_profile: mortgage.applicant_detail?.client_profile,
    }
    const coApplicantDetailReduced = {
      id: mortgage.coapplicant_detail?.id,
      first_name: mortgage.coapplicant_detail?.first_name,
      last_name: mortgage.coapplicant_detail?.last_name,
      email: mortgage.coapplicant_detail?.email,
      financnik: mortgage.coapplicant_detail?.financnik,
      client_profile: mortgage.coapplicant_detail?.client_profile,
    }
    mortgage.applicant_detail = applicantDetailReduced;
    mortgage.coapplicant_detail = coApplicantDetailReduced;
    delete mortgage.dti_cache
  });
  return newMortgages;
}
