<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      <div>
        <span class="pop-800">{{ summaryData.clientInfo.type }}ov</span> dlh <span class="pop-800">{{ summaryData.zostatok }} EUR</span> od <span class="pop-800">{{ translateBank(summaryData.banka) }}</span>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'banka'" :value="translateBank(summaryData.banka)"/>
        <text-value-holder :label="'typ'" :value="summaryData?.typ"/>
        <text-value-holder :label="'pôvodný objem'" :value="summaryData.povodna_suma"/>
        <text-value-holder :label="'zostatok'" :value="summaryData.zostatok"/>
        <text-value-holder :label="'splátka'" :value="summaryData.vyska_splatky"/>
        <!-- <file-download-holder
          v-if="debtAgreement"
          v-for="(file, index) in debtAgreement.uploaded"
          :key="index"
          :cloud-file="file"
          :label="file.name"
        /> -->
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import translateFromDBValue from "@/mixins/translateFromDBValue";
import FileDownloadHolder from "@/components/shared/PopupHandler/Popups/ApplicationGenerator/FileDownloadHolder.vue";

export default {
  name: "DebtSummary",
  components: {FileDownloadHolder, TextValueHolder},
  props: {
    summaryData: Object,
    openedDefault: Boolean,
  },
  mixins: [
    translateFromDBValue
  ],
  created() {
    // this.debtAgreement = this.$store.getters["mortgageDetailCloud/getCloudMissingFileByType"](this.summaryData.cloud, "povodna_uverova_zmluva");
    this.opened = this.openedDefault;
    if (this.summaryData.is_visible) {
      if (this.summaryData.is_active) {
        this.type = "nerefinancovaný"
      } else {
        this.type = "splatený zo svojho"
      }
    }
    const mortgageId = this.$store.getters["mortgageDetailState/getMortgage"].id
    if (this.summaryData.refinancovany_hypotekou === mortgageId) this.type = "refinancovaný";
  },
  data: () => ({
    debtAgreement: null,
    opened: false,
    type: null,
  }),
}
</script>

<style scoped>

</style>
