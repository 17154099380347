import { render, staticRenderFns } from "./TradesmanSummary.vue?vue&type=template&id=072eb0b0&scoped=true&"
import script from "./TradesmanSummary.vue?vue&type=script&lang=js&"
export * from "./TradesmanSummary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072eb0b0",
  null
  
)

export default component.exports