<template>
  <div class="popup-form">
    <div
      class="content-form-popup"
    >
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img class="nav-link-icon filter-primary" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg" alt=""/>
        </div>
        <div class="blackcolor pop-800">Schválenie žiadosti</div>
        <button class="w-button saveformBtn" @click="saveStatus">Aktualizovať</button>
      </div>
      
      <!-- <div class="input-group">
        <label class="field-label">Kedy bola žiadosť schválená</label>
        <input
          name="dateOfUpload"
          type="date"
          :max="todayLong"
          class="text-input w-input date-input"
          maxlength="256"
        />
      </div> -->
      <div class="splitter">Potvrďte alebo aktualizujte podmienky schválenia</div>
      <div class="input-group">
        <label for="urok" class="field-label">Požadovaná sadzba</label
        ><input
        v-model.number="formData.sadzba_pozadovana"
        type="number"
        readonly="readonly"
        class="text-input w-input"
        maxlength="256"
        min="1"
        step=".01"
        name="urok"
        id="urok"
      />
      </div>
      <div class="input-group">
        <label for="urok" class="field-label">Schválená sadzba</label
        ><input
        v-model.number="formData.sadzba_schvalena"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        step=".01"
        name="urok"
        id="urok"
      />
      </div>
      <div class="input-group">
        <label for="fixacia" class="field-label">Fixácia<strong></strong></label
        ><input
        v-model.number="formData.fix"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="fixacia"
        id="fixacia"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru nadobudnutie<strong></strong></label
        ><input
        v-model.number="formData.suma_nadobudnutie"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru refinancovanie<strong></strong></label
        ><input
        v-model.number="formData.suma_refinancovanie"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="vyskauveru" class="field-label">Výška úveru bezúčel<strong></strong></label
        ><input
        v-model.number="formData.suma_bezucel"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="vyskauveru"
        id="vyskauveru"
      />
      </div>
      <div class="input-group">
        <label for="splatnost" class="field-label">Splátka<strong></strong></label
        ><input
        v-model="formData.splatka"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="splatka"
        id="splatka"
      />
      </div>
      <div class="input-group">
        <label for="splatnost" class="field-label">Poplatok<strong></strong></label
        ><input
        v-model="formData.poplatok_za_poskytnutie"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="poplatok_za_poskytnutie"
        id="poplatok_za_poskytnutie"
      />
      </div>
      <div class="input-group">
        <label for="splatnost" class="field-label">Splatnosť<strong></strong></label
        ><input
        v-model.number="formData.splatnost"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        name="splatnost"
        id="splatnost"
      />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MortgageApplicationApprovalPopup",
  props: {
    payload: Object,
  },
  created() {
    this.formData.sadzba_pozadovana = this.payload.sadzba_pozadovana;
    this.formData.sadzba_schvalena = this.payload.sadzba_schvalena;
    this.formData.fix = this.payload.fix;
    this.formData.suma_nadobudnutie = this.payload.suma_nadobudnutie;
    this.formData.suma_refinancovanie = this.payload.suma_refinancovanie;
    this.formData.suma_bezucel = this.payload.suma_bezucel;
    this.formData.splatnost = this.payload.splatnost;
    this.formData.splatka = this.payload.splatka;
    this.formData.poplatok_za_poskytnutie = this.payload.poplatok_za_poskytnutie;
  },
  methods: {
    saveStatus () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: true, form: this.formData, stage: "schvalena"});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
    closePopup () {
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
      this.preverDestroy = true;
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
  beforeDestroy () {
    if (this.preverDestroy) return;
    this.$store.commit("popupManagerState/setPopupSuccessResult", {status: false});
  },
  data: () => ({
    preverDestroy: false,
    todayLong: new Date().toISOString().substr(0, 10),
    formData: {
      sadzba_pozadovana: null,
      sadzba_schvalena: null,
      fix: null,
      suma_nadobudnutie: null,
      suma_refinancovanie: null,
      suma_bezucel: null,
      splatnost: null,
      splatka: null,
      poplatok_za_poskytnutie: null
    },
  }),
}
</script>

<style scoped>

</style>
