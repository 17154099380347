<template>
  <div class="main-view">
    <client-financing-offer-pop-up :show="showPdf" :mortgage-applications="mortgageApplications" :mortgage="mortgage"/>
    <handlers-wrapper-button :floating-handlers="floatingHandler" :handlers="getHandlers()"/>
    <div class="div-block-30">
      <div>
        <choice-user-detail-item
          :mortgage-id="mortgage.id"
          :client="applicant"
          :client-info="getApplicantInfo()"
          :co-applicant-present="!!coApplicant"
          :missing-info="missingInformation"
        />
        <choice-user-detail-item
          :mortgage-id="mortgage.id"
          :client="coApplicant"
          :client-info="getCoApplicantInfo()"
          :co-applicant-present="!!coApplicant"
          :missing-info="missingInformation"
        />
        </div>
      <founded-property :mortgage-id="mortgage.id"/>
    </div>
    <div
      class="empty-bag-wrapper"
      v-if="missingInformation"
    >
      <img src="@/images/default-page.choices.png" width="200" height="175" alt="">
      <div style="max-width: 35%">
      <span class="txt-center">
        Zatiaľ tu nič nie je, prvé vypočítané ponuky sa tu zobrazia po doplnení <span
        v-html="missingInformationStatement"/>
      </span>
      </div>
    </div>
    <div
      class="div-block-105"
      v-if="limitsLoader && !missingInformation"
    >
      <nadobudnutie-wrapper :mortgage="mortgage" :total-limit="maxLimit" :loan-prpose="loanPurpose"/>
      <refinancovanie-wrapper :mortgage="mortgage" :total-limit="maxLimit" :loan-prpose="loanPurpose"/>
      <div class="groupf102div">
        <ucelova-cast-wrapper :mortgage="mortgage" :total-limit="maxLimit" :loan-prpose="loanPurpose"/>
        <div class="div-block-102">  
          <range-slider
            @change-event="setMortgageValue($event, 'own_funds')"
            :id-not-selected="'not-selected-vlastne-prostriedky'"
            :id-selected="'selected-vlastne-prostriedky'"
            :min="0"
            :max="maxLimit"
            :default-value="mortgage.own_funds ? mortgage.own_funds : 0"
            :twoSideValues="false"
            :description="'Vlastné prostriedky'"
            :unit="'EUR'"
            :value-correction="null"
            />
        </div>
      </div>
      <div
        class="result-block-broker-app empty-bag-wrapper" style="max-height: 400px;"
        v-if="lowincome"
      >
        <img src="@/images/default-page.choices.png" width="200" height="175" alt="">
        <div style="max-width: 50%">
        <span class="txt-center">
          Príliš nízky príjem, buď Vaše výdavky prevyšujú príjmy alebo na základe Vášho príjmu banka nevie poskytnúť financovanie. <br><br>1. Pozrite sa, či pri niektorom z príjmov v ľavej čati nesvieti DOKONČIŤ. <br><br> 2. Po upravení príjmu nezadudnite znova nastaviť sumu hypoteký posuvníkom.
        </span>
        </div>
      </div>
      <mortgage-applications-choice-wrapper v-else :mortgage="mortgage" :mortgage-offers="mortgageApplications"/>
    </div>
    <div class="limits-loader" v-if="!limitsLoader && !missingInformation">
      <img class="image-limits-loading" src="@/images/limits-loader.png" alt="">
      <span class="blc-color MGright20px">prepočítavame limity na nastavenie požadovanej výšky úveru</span>
      <v-progress-circular
        class="loading-spinner small transparent"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import BonitaOfApplicants
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/BonitaOfApplicants";
import FoundedProperty
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/FoundedProperty";
import ChoiceUserDetailItem
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/ChoiceUserDetailItem";
import MortgageApplicationsChoiceWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/MortgageApplicationsChoiceWrapper";
import RangeSlider from "../../../../components/shared/RangeSlider";
import RefinancovanieWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/RefinancovanieWrapper";
import NadobudnutieWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/NadobudnutieWrapper";
import UcelovaCastWrapper
  from "../../../../components/broker/KartaHypoteky/KartaHypotekyDetail/ApplicationChoice/UcelovaCastWrapper";
import { mapGetters } from "vuex";
import HandlersWrapperButton from "../../../../components/shared/PopupHandler/HandlerWrapper/HandlersWrapperButton";
import mortgageUtils from "../../../../mixins/mortgageUtils";
import ClientFinancingOfferPopUp
  from "@/components/shared/PopupHandler/Popups/ClientFinnancingOffer/ClientFinancingOfferPopUp.vue";

export default {
  name: "ApplicationChoice",
  components: {
    ClientFinancingOfferPopUp,
    HandlersWrapperButton,
    UcelovaCastWrapper,
    BonitaOfApplicants,
    FoundedProperty,
    ChoiceUserDetailItem,
    MortgageApplicationsChoiceWrapper,
    RangeSlider,
    RefinancovanieWrapper,
    NadobudnutieWrapper,
  },
  computed: {
    limitsLoader () {
      return this.$store.getters["appState/getLimitsLoader"];
    },
    ...mapGetters({
      mortgageOffersGetter: "mortgageDetailState/getOffers",
      clientFinancingOffersGetter: "popupManagerState/getClientFinancingOffersPopup",
      limitsGetter: "mortgageDetailState/getLimits",
      limitsChanged: "mortgageDetailState/getLimitsChanged",
      amountsGetter: "mortgageDetailState/getAmounts",
      coApplicantIncomes: "mortgageDetailState/getCoApplicantIncomes",
      applicantIncomes: "mortgageDetailState/getApplicantIncomes",
      limitCorrection: "mortgageDetailState/getLimitCorrection",
    }),
  },
  mixins: [
    mortgageUtils
  ],
  props: {
    mortgage: [Object, null],
    applicant: [Object, null],
    coApplicant: [Object, null],
  },
  async created () {
    this.checkForApplicantsMissingInfo();
    if (this.missingInformation) {
      this.refreshMissingInfo();
      return;
    }
    this.setLoanPurpose();
    const newLimits = this.$store.getters["mortgageDetailState/getLimits"];
    this.setUpperLimitLoanInfo(newLimits);
    const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
    if (this.areAmountsZero(amounts)) {
      const mortgagePurpose = this.getMortgagePurpose();
      this.$store.commit("mortgageDetailState/setAmount", {
        type: `suma_${mortgagePurpose}`,
        value: this.maxLimit ? this.maxLimit : 0,
      })
    }
    await this.$store.dispatch("mortgageDetailState/fetchOffers");
    this.mortgageApplications = this.$store.getters["mortgageDetailState/getOffers"];
  },
  watch: {
    limitCorrection (correction) {
      this.maxLimit += correction;
    },
    async missingInformation(newValue, oldValue) {
      if (!newValue && oldValue) {
        const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
        await this.waitUntilLimitsFilled();
        if (this.amountsAreZero(amounts) && this.maxLimit) {
          this.$store.commit("mortgageDetailState/setAmounts", {
            suma_nadobudnutie: this.maxLimit,
            suma_refinancovanie: 0,
            suma_bezucel: 0,
          });
          this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
        }
      }
    },
    limitsChanged (limitsChange) {
      if (!limitsChange) this.floatingHandler = [];
      else this.floatingHandler = [{component: "LimitsRefreshButton"}];
    },
    mortgageOffersGetter (newApplications) {
      this.mortgageApplications = newApplications;
    },
    clientFinancingOffersGetter(show) {
      this.showPdf = show;
    },
    coApplicantIncomes () {
      this.refreshMissingInfo();
    },
    applicantIncomes () {
      this.refreshMissingInfo();
    },
    // mortgage () {
    //   if (this.missingInformation) return;
    //   // this.$store.dispatch("mortgageDetailState/fetchOffers", newMortgage.id);
    //   // this.$store.dispatch("mortgageDetailState/fetchLimits", newMortgage.id);
    // },
    applicant () {
      this.refreshMissingInfo();
    },
    coApplicant () {
      this.refreshMissingInfo();
    },
    limitsGetter (newLimits) {
      if (!this.missingInformation) {
        this.setLoanPurpose();
        this.setUpperLimitLoanInfo(newLimits);
        this.$store.dispatch("mortgageDetailState/fetchOffers");
      }
    },
  },
  methods: {
    a() {
      this.$store.commit("mortgageDetailState/setLimitsChanged", true);
    },
    waitUntilLimitsFilled () {
      return new Promise((resolve) => {
        let counter = 0;
        const int = setInterval(() => {
          if (this.maxLimit) {
            clearInterval(int);
            resolve(true);
          } else {
            counter++;
          }
          if (counter > 100) {
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    areAmountsZero(object) {
      return object.suma_bezucel === 0 && object.suma_nadobudnutie === 0 && object.suma_refinancovanie === 0;
    },
    getHandlers () {
      return [
        {
          component: "DocumentCloudHandler",
          classCst: "button-item-handler white&#45;&#45;text no-decoration",
          iconButton: true,
          rules: ['single'],
          cloudMeta: this.$store.getters["mortgageDetailCloud/getFullClouds"],
        },
        {
          component: "CalculationsHistoryHandler",
          history: this.mortgage.offers_filter_history,
          rules: ['priority'],
        },
        {
          component: "ClientFinancingOfferHandler",
          rules: ['priority'],
        },
        {
          component: "MetodicTable",
          rules: ['priority'],
        },
        {
          component: "posudokOrderBtn",
          rules: ['priority'],
        }
      ]
    },
    refreshMissingInfo () {
      this.checkForApplicantsMissingInfo();
      if (!this.missingInformation) {
        // this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
        // this.$store.dispatch("mortgageDetailState/fetchLimits", this.mortgage.id);
      } else {
        this.updateMissingStatementInfo();
      }
    },
    updateMissingStatementInfo () {
      this.missingInformationStatement = "";
      const ap = this.missingInformationData.applicant;
      const coap = this.missingInformationData.coApplicant;
      if (!ap.income && !ap.bonita) {
        this.missingInformationStatement = this.missingInformationStatement + "aspoň <strong>jedného príjmu a veku žiadateľa (v časti bonita)</strong>"
      } else if (!ap.income) {
        this.missingInformationStatement = this.missingInformationStatement + "aspoň <strong>jedného príjmu žiadateľa</strong>"
      } else if (!ap.bonita) {
        this.missingInformationStatement = this.missingInformationStatement + "<strong>veku žiadateľa (v časti bonita)</strong>"
      }
      if (!this.coApplicant) return;
      if (!coap.income && !coap.bonita) {
        this.missingInformationStatement = this.missingInformationStatement + " a aspoň <strong>jedného príjmu a veku spolužiadateľa (v časti bonita)</strong>"
      } else if (!coap.income) {
        this.missingInformationStatement = this.missingInformationStatement + " a aspoň <strong>jedného príjmu spolužiadateľa</strong>"
      } else if (!coap.bonita) {
        this.missingInformationStatement = this.missingInformationStatement + " a <strong>veku spolužiadateľa (v časti bonita)</strong>"
      }
    },
    checkForApplicantsMissingInfo () {
      let isMissing = false;
      const applicant = {
        income: true,
        bonita: true,
      };
      const coApplicant = {
        income: true,
        bonita: true,
      }
      if (this.applicant) {
        if (this.$store.getters["mortgageDetailState/getClientIncomes"]('applicant').length === 0) {
          applicant.income = false;
          this.missingInformation = true;
          isMissing = true;
        }
        const cp = this.applicant.client_profile
        // console.log(this.applicant);
        if (!cp.vek_v_case_vyplnania && !cp.datum_narodenia) {
          applicant.bonita = false;
          this.missingInformation = true;
          isMissing = true;
        }
      }
      if (this.coApplicant) {
        if (this.$store.getters["mortgageDetailState/getClientIncomes"]('coApplicant').length === 0) {
          // zakomentovanie potreby prrijmu spoluziadatelaˇ
          // coApplicant.income = false;
          // this.missingInformation = true;
          // isMissing = true;
        }
        const cp = this.coApplicant.client_profile;
        if (!cp.vek_v_case_vyplnania && !cp.datum_narodenia) {
          coApplicant.bonita = false;
          this.missingInformation = true;
          isMissing = true;
        }
      }
      this.missingInformationData = {applicant, coApplicant};
      if (!isMissing) this.missingInformation = false;
    },
    amountsAreZero(amounts) {
      for (const amount of Object.values(amounts)) {
        if (amount === 0) continue;
        return false;
      }
      return true;
    },
    checkIfLimitIsEnough (maxLimit) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let currentAmount = 0;
      for (const amount of Object.values(amounts)) {
        currentAmount += amount;
      }
      let excessAmount = currentAmount - maxLimit;
      return excessAmount < 0;
    },
    setUpperLimitLoanInfo (limits) {
      if (!limits) return;
      let maxLimit = 0;
      let bankLoan = null;
      for (const [key, limit] of Object.entries(limits)) {
        if (key === "max_pozicka_podla_ltv") continue;
        const loanObject = limit[this.loanPurpose];
        if (loanObject && loanObject.success && maxLimit < loanObject.value) {
          maxLimit = loanObject.value;
          bankLoan = key;
        }
      }
      if (!this.checkIfLimitIsEnough(maxLimit)) {
        this.maxLimit = Math.floor(maxLimit);
        const purpose = `suma_${this.getMortgagePurpose()}`;
        const amountsObject = {
          suma_refinancovanie: 0,
          suma_bezucel: 0,
          suma_nadobudnutie: 0,
        };
        amountsObject[purpose] = this.maxLimit;
        this.$store.commit("mortgageDetailState/setAmounts", amountsObject);
        this.$store.commit("appState/setSuccessMessage", {
          text: "Suma hypotéky presahovala limity, bola nastavená maximálna výška úveru podľa typu hypotéky",
          type: "error"
        });
        if (this.maxLimit == 0)
          this.lowincome = true;
      } else {
        if (!!bankLoan) {
          this.$store.commit("mortgageDetailState/setLoanLimit", maxLimit);
          this.maxLimit = Math.floor(maxLimit);
        } else {
          this.$store.commit("mortgageDetailState/nullAmounts");
          this.maxLimit = 0;
        }
      }
    },
    // setDefaultApplicationsData () {
    //   const appData = this.$store.getters["mortgageDetailState/getApplicationsData"];
    //   this.$store.commit("mortgageDetailState/setApplicationsData", {
    //     maximalna_splatnost: this.maxDue,
    //     splatnost_v_rokoch: this.maxDueRelated,
    //     fix: appData.fix,
    //   })
    // },
    setLoanPurpose () {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let maxAmount = Number.MIN_SAFE_INTEGER;
      let amountKey = {};
      for (const [key, amount] of Object.entries(amounts)) {
        if (amount < maxAmount) continue;
        maxAmount = amount;
        amountKey = key;
      }
      if (maxAmount === 0) {
        this.loanPurpose = "nadobudnutie";
        this.$store.commit("appState/setSuccessMessage", {
          text: "Príliš nízky príjem na výpočet hypotéky, upravte existujúci alebo pridajte ďalšie",
          type: "error"
        });
        return;
      }
      switch (amountKey) {
        case "suma_bezucel":
          this.loanPurpose = "bezucel";
          break;
        case "suma_refinancovanie":
          this.loanPurpose = "refinancovanie";
          break;
        case "suma_nadobudnutie":
          this.loanPurpose = "nadobudnutie";
          break;
      }
    },
    setMortgageValue (value, field) {
      const mortgage = {
        id: this.mortgage.id,
        object: {},
      }
      mortgage.object[field] = value;
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgage);
    },
    getApplicantInfo () {
      return {
        type: "Žiadateľ",
        type_code: "applicant",
        id: this.applicant.id,
        firstName: this.applicant.first_name,
        lastName: this.applicant.last_name
      };
    },
    getCoApplicantInfo () {
      if (!this.coApplicant) return {
        type: "Spolužiadateľ",
        type_code: "coApplicant",
        id: null,
        firstName: null,
        lastName: null
      };
      return {
        type: "Spolužiadateľ",
        type_code: "coApplicant",
        id: this.coApplicant.id,
        firstName: this.coApplicant.first_name,
        lastName: this.coApplicant.last_name
      };
    },
  },
  data: () => ({
    firstTime: true,
    floatingHandler: [],
    pdfFile: "/document.pdf",
    mortgageApplications: null,
    showPdf: false,
    i: 0,
    limits: null,
    maxLimit: null,
    maxDue: null,
    maxDueRelated: null,
    loanPurpose: null,
    missingInformationStatement: "",
    missingInformationData: null,
    missingInformation: false,
    lowincome: false,
  }),
};
</script>

<style lang="scss" scoped>

.empty-bag-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column
}

</style>
