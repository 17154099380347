<template>
  <div
    class="card-content pipeline"
  >
    <div>
      <div
        class="column-title"
      >
        <div
          class="div-block-110 bordered"
        >
          <div class="text-block-15">1</div>
        </div>
        <div class="bold">VÝBER</div>
      </div>
      <div class="divider-margin"></div>
      <hypoteka-item-detail
        v-for="(mortgage, key) in mortgagesGetterComp.vyber_riesenia"
        :key="key"
        :mortgage="mortgage"
        @remove-mortgage="removeMortgage(mortgage)"
        :client="mortgage.applicant"
        :stage="'vyber_riesenia'"
        :active-delete-button="show"
      />
      <div class="m-3 d-flex justify-content-center align-items-center"
           v-if="shouldDisplayNextPageButton('vyber_riesenia')">
        <div class="loading-spinner" v-if="loading.vyber_riesenia">
          <v-progress-circular
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
        </div>
        <span class="text-small bl-color pointer" @click="nextPage('vyber_riesenia')" v-else>
          + načítaj ďalšie
        </span>
      </div>
    </div>
    <!-- hypoteky s kompletizaciou podkladov na ziadost -->
    <div>
      <div
        class="column-title"
      >
        <div
          class="div-block-110 bordered"
        >
          <div class="text-block-15">2</div>
        </div>
        <div class="bold">PRÍPRAVA</div>
      </div>
      <div class="divider-margin"></div>
      <hypoteka-item-detail
        v-for="(mortgage, key) in mortgagesGetterComp.kompletizacia_podkladov"
        :key="key"
        :mortgage="mortgage"
        @remove-mortgage="removeMortgage(mortgage)"
        :client="mortgage.applicant"
        :stage="'kompletizacia_podkladov'"
        :active-delete-button="show"
      />
      <div class="m-3 d-flex justify-content-center align-items-center"
           v-if="shouldDisplayNextPageButton('kompletizacia_podkladov')">
        <div class="loading-spinner" v-if="loading.kompletizacia_podkladov">
          <v-progress-circular
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
        </div>
        <span class="text-small bl-color pointer" @click="nextPage('kompletizacia_podkladov')" v-else>
          + načítaj ďalšie
        </span>
      </div>
    </div>

        <!-- hypoteky na schvalovani -->
        <div>
      <div
        class="column-title"
      >
        <div
          class="div-block-110 bordered"
        >
          <div class="text-block-15">3</div>
        </div>
        <div class="bold">SHVAĽOVANIE</div>
      </div>
      <div class="divider-margin"></div>
      <hypoteka-item-detail
        v-for="(mortgage, key) in mortgagesGetterComp.schvalovanie"
        :key="key"
        :mortgage="mortgage"
        @remove-mortgage="removeMortgage(mortgage)"
        :client="mortgage.applicant"
        :stage="'schvalovanie'"
        :active-delete-button="show"
      />
      <div class="m-3 d-flex justify-content-center align-items-center"
           v-if="shouldDisplayNextPageButton('schvalovanie')">
        <div class="loading-spinner" v-if="loading.schvalovanie">
          <v-progress-circular
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
        </div>
        <span class="text-small bl-color pointer" @click="nextPage('schvalovanie')" v-else>
          + načítaj ďalšie
        </span>
      </div>
    </div>

    <!-- hypoteky na podpise ziadosti -->
    <div>
      <div
        class="column-title"
      >
        <div
          class="div-block-110 bordered"
        >
          <div class="text-block-15">4</div>
        </div>
        <div class="bold">SERVIS</div>
      </div>
      <div class="divider-margin"></div>
      <hypoteka-item-detail
        v-for="(mortgage, key) in mortgagesGetterComp.servis"
        :key="key"
        :mortgage="mortgage"
        @remove-mortgage="removeMortgage(mortgage)"
        :client="mortgage.applicant"
        :stage="'servis'"
        :active-delete-button="show"
      />
      <div class="m-3 d-flex justify-content-center align-items-center"
           v-if="shouldDisplayNextPageButton('servis')">
        <div class="loading-spinner" v-if="loading.servis">
          <v-progress-circular
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
        </div>
        <span class="text-small bl-color pointer" @click="nextPage('servis')" v-else>
          + načítaj ďalšie
        </span>
      </div>
    </div>

    <objects-remove-pop-up
      :objects-component="'MortgageObjects'"
      @restore="restoreApplication($event)"
      :show="show"
      :objects="mortgagesGetterComp.zrusena"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HypotekaItemDetail from "./HypotekaItemDetail";
import ObjectsRemovePopUp from "@/components/shared/PopupHandler/Popups/ObjectsRemove/ObjectsRemovePopUp.vue";

export default {
  name: "RequestItemsWrapper",
  components: {
    ObjectsRemovePopUp,
    HypotekaItemDetail
  },
  computed: {
    mortgagesGetterComp() {
      return this.$store.getters["mortgagesState/getMortgages"];
    },
    ...mapGetters({
      mortgagesGetter: "mortgagesState/getMortgages",
      mortgagesCountGetter: "mortgagesState/getMortgagesCount",
      removalPopupGetter: "popupManagerState/getMortgageApplicationsRemovePopup",
    }),
  },
  watch: {
    removalPopupGetter(e) {
      this.show = e;
    },
    mortgagesGetter (newMortgages) {
      this.mortgages = JSON.parse(JSON.stringify(newMortgages));
    },
    mortgagesCountGetter (newCounts) {
      this.mortgagesCount = newCounts;
    }
  },
  created () {
    this.role = this.$store.getters["userState/getRole"];
    this.mortgages = this.mortgagesGetter;
    this.mortgagesCount = this.mortgagesCountGetter;
  },
  methods: {
    removeMortgage(mortgage) {
      this.$store.dispatch("mortgagesState/moveMortgageToDifferentStage", {...mortgage, stage_old: mortgage.stage, stage: "zrusena"});
    },
    restoreApplication(mortgage) {
      this.$store.dispatch("mortgagesState/moveMortgageToDifferentStage", {...mortgage, stage_old: "zrusena", stage: mortgage.stage_old});
    },
    async nextPage (stage) {
      this.loading[stage] = true;
      await this.$store.dispatch("mortgagesState/nextPageMortgages", stage);
      this.loading[stage] = false;
    },
    shouldDisplayNextPageButton (stage) {
      return this.mortgages[stage].length !== this.mortgagesCount[stage];
    }
  },
  data: () => ({
    show: false,
    loading: {
      vyber_riesenia: false,
      podpis_ziadosti: false,
      kompletizacia_podkladov: false,
      schvalovanie: false,
      servis: false,
    },
    mortgages: null,
    mortgagesCount: null,
    role: null,
  }),
};
</script>
<style lang="scss" scoped>
@import "node_modules/vue-kanban/src/assets/kanban";

.divider-margin {
  padding-bottom: 25px;
}


.drag-item {
  height: auto;
  background: none;
  margin: 10px 0;
  padding: 0;
}

.drag-container {
  max-width: none;
  margin: 0 30px;
}

.drag-column-header {
  display: block;
  padding: 0;
  text-align: center;
}

.drag-column {
  & h2 {
    text-transform: none;
  }

  background: none;
}
</style>
