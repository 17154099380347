<template>
  <div>
    <div class="header-block">
      <div class="d-flex justify-content-between">
        <div>Orientačné DTI</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.value | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div>Primerný mes. príjem</div>
        <div>
          <v-progress-circular
            v-if="dtiLoading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ estimatedDti?.extra?.prijem_mo | dotTheAmount }} EUR</div>
        </div>
      </div>
      <div v-if="income?.error">
        <br>
        <div
          v-for="(error, key) in income.error"
          :key="key"
          class="error-text bl-color"
        >
          {{ error }}
        </div>
      </div>
    </div>
    
    <div class="section-title">
        <div class="text-small light">Údaje pre výpočet príjmu zadávajte za posledné ukončené účtovné obdobie, v prípade ak tento príjem ešte nebol zdanený, vytvorte ďalší príjem z SRO s údajmi za predchádzajúce účtovné obdobie. Ako čítať daňové priznanie SRO <a class="link" href="https://www.bankari.sk/ako-na-to/kde-najdete-hodnotu-trzieb-spolocnosti-na-danovom-priznani-pre-vypocet-max-uveru" target="_blank">TU</a></div>
    </div>
    <form @submit.prevent="saveIncome" id="income-submit">
      <input
        type="text"
        v-model="form.zdanenie_minuly_rok"
        style="opacity: 0; position: absolute"
        required
      />
      <div class="dropdown-input-custom rest-input required">
        <dropdown-select
          @select="setDropdownValue($event, 'zdanenie_minuly_rok')"
          :id="'zdanenie_minuly_rok'"
          :init-value="form.zdanenie_minuly_rok"
          :options="booleanForm"
          normal
          :name="'Bol príjem zdanený?'"
        />
      </div>
      <div class="input-group">
        <label for="trzby" class="field-label"
        >Tržby (riadok 1 str. 4 účtovnej závierky)
        </label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.minulorocny_obrat"
          type="number"
          step="0.01"
          class="text-input w-input required"
          name="trzby"
          data-name="trzby"
          id="trzby"
          required
        />
      </div>
      <div class="input-group">
        <label for="zisk" class="field-label"
        >Zisk (riadok 38, str. 4 účtovnej závierky)</label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.zisk_sro"
          type="number"
          step="0.01"
          class="text-input w-input required"
          name="zisk"
          data-name="zisk"
          id="zisk"
          required
        />
      </div>
      <div class="input-group">
        <label for="zisk" class="field-label"
        >Z toho zisk vyplatený na účet</label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.zisk_sro_vyplateny"
          type="number"
          step="0.01"
          class="text-input w-input required"
          name="zisk"
          data-name="zisk"
          id="zisk"
          required
        />
      </div>
      <div class="input-group">
        <label for="Podiel" class="field-label"
        >Podiel v spoločnosti<strong></strong
        ></label>
        <input
          @change="showEstimatedDtiToUser"
          v-model.number="form.podiel_v_sro"
          type="number"
          class="text-input w-input required"
          name="podiel"
          min="0"
          max="100"
          data-name="Podiel"
          id="Podiel"
          required
        />
      </div>
      <div class="section-title">
        <div class="text-small light">O spoločnosti</div>
        <div class="text-small light"> údaje overíte na
            <a
              :class="[ico ? 'link pointer' : 'link pointer noselect']"
              target="_blank"
              @click.prevent="navigateToFinstat"
            >
              finstat.sk
            </a>
        </div> 
      </div>
      <div
        class="input-group"
      >
        <label for="Typ-pr-jmu-2" class="field-label"
        >IČO <span class="pop-300">(údaje sa doplnia automaticky)</span></label
        ><input
        @change="initializeEmployerByIco"
        v-model="ico"
        type="text"
        class="text-input w-input"
        minlength="8"
        name="icoZamestnavatel"
        data-name="Typ Pr Jmu 2"
        placeholder=""
        id="Typ-pr-jmu-2"
      />
      </div>
      <div class="input-group">
        <label for="obchodny-nazov" class="field-label"
        >Obchodný názov</label
        ><input
        v-model="employerDetail.name"
        readonly
        type="text"
        class="input-color-disabled text-input w-input"
        maxlength="256"
        name="obchodny-nazov"
        data-name="obchodny-nazov"
        placeholder=""
        id="obchodny-nazov"
      />
      </div>
      <div class="input-group">
        <label class="field-label"
        >Sídlo</label
        ><input
        v-model="employerDetail.slovensko_digital_response.formatted_address"
        readonly
        type="text"
        class="input-color-disabled text-input w-input"
        maxlength="256"
        name="sidlo"
        data-name="sidlo"
        placeholder=""
        id="sidlo"
      />
      </div>
      <div class="input-group">
        <label class="field-label"
        >Dátum vzniku</label>
        <input
          v-model="form.podnikanieOd"
          type="date"
          class="input-color text-input w-input required"
          required
        >
      </div>
      <div class="dropdown-input-custom rest-input required">
        <dropdown-select
          @select="setDropdownValue($event, 'odvetvie')"
          :id="'sro_odvetvie'"
          :init-value="form.odvetvie"
          :options="formTemplate.zamestnavatel_odvetvie.choices"
          normal
          :name="'Odvetvie'"
          required
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'komora')"
          :id="'sro_komora'"
          :init-value="form.komora"
          :options="formTemplate.komora.choices"
          normal
          :name="'Komora'"
        />
      </div>
    </form>
  </div>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import {getEmployerByIco, getEmployerByID, getSroDTI} from "@/services/brokerService";
import ChipInput from "../../../ChipInput";

export default {
  name: "SROForm",
  components: {ChipInput, DropdownSelect},
  props: {
    income: [Object, null],
    formTemplate: Object,
  },
  watch: {
    "form.komora"(newKomora) {
      if (newKomora) this.form.odvetvie = null;
    },
    "form.odvetvie"(newOdvetvie) {
      if (newOdvetvie) this.form.komora = null;
    },
    income(updatedIncome) {
      if (typeof updatedIncome.zamestnavatel === "object") {
        this.employerDetail = updatedIncome.zamestnavatel;
        this.ico = updatedIncome.zamestnavatel.ico;
      }
    }
  },
  async created () {
    if (this.income) {
      this.form.podnikanieOd = this.income.zamestnanie_od;
      this.form.zdanenie_minuly_rok = this.income.zdanenie_minuly_rok;
      this.form.minulorocny_obrat = this.income.minulorocny_obrat;
      this.form.zisk_sro = this.income.zisk_sro;
      this.form.podiel_v_sro = this.income.podiel_v_sro;
      this.form.zisk_sro_vyplateny = this.income.zisk_sro_vyplateny;
      this.form.odvetvie = this.income.zamestnavatel_odvetvie;
      this.form.komora = this.income.komora;
      if (this.income.zamestnavatel && typeof this.income.zamestnavatel === "object") {
        this.ico = this.income.zamestnavatel.ico;
        this.employerDetail = this.income.zamestnavatel;
        this.form.podnikanieOd = this.employerDetail.datum_zalozenia;
      } else if (typeof this.income.zamestnavatel === "number") {
        await this.initializeEmployerById(this.income.zamestnavatel);
        this.ico = this.employerDetail.ico;
        this.form.podnikanieOd = this.employerDetail.datum_zalozenia;
      }
      this.showEstimatedDtiToUser();
    }
  },
  methods: {
    navigateToFinstat() {
      if (!this.ico) {
        this.$store.commit("appState/setSuccessMessage", {
          text: "Pre zobrazenie SRO na finstate zadajte ičo",
          type: "error",
        });
        return;
      }
      window.open(`https://www.finstat.sk/${this.ico}`, "_blank");
    },
    pickMoIncome () {
      if (!this.estimatedDti) return null;
      if (this.estimatedDti.extra.dti_z_obratu.value === this.estimatedDti.value) {
        return this.estimatedDti.extra.dti_z_obratu.extra.prijem_mo;
      } else if (this.estimatedDti.extra.dti_zo_zisku.value === this.estimatedDti.value) {
        return this.estimatedDti.extra.dti_zo_zisku.extra.prijem_mo;
      }
      return null;
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
    async initializeEmployerById(id) {
      this.employerDetail = await getEmployerByID(id);
      this.form.podnikanieOd = this.employerDetail.datum_zalozenia.substr(0, 10);
    },
    async initializeEmployerByIco () {
      if (this.ico === "") {
        this.employerDetail = {
          slovensko_digital_response: {
            formatted_address: null,
          },
        };
      } else {
        try {
          this.employerDetail = await getEmployerByIco({ico: this.ico});
          this.form.podnikanieOd = this.employerDetail.datum_zalozenia.substr(0, 10);
        } catch (e) {
          this.employerDetail = {
            datum_zalozenia: null,
            slovensko_digital_response: {
              formatted_address: null,
            },
          };
          this.$store.commit("appState/setSuccessMessage", {
            text: "Zamestnávateľ podľa iča nebol nájdený",
            type: "error"
          });
        }
      }
    },
    async showEstimatedDtiToUser () {
      this.dtiLoading = true;
      const incomeData = {
        trzby: this.form.minulorocny_obrat,
        vyplateny_zisk: this.form.zisk_sro_vyplateny,
        zisk: this.form.zisk_sro,
        podiel: this.form.podiel_v_sro,
      };
      this.estimatedDti = null;
      if (this.checkValue(incomeData.podiel) && this.checkValue(incomeData.zisk) && this.checkValue(incomeData.trzby)) {
        const dtiData = await getSroDTI(incomeData);
        this.pickHighestDti(dtiData);
      }
      this.dtiLoading = false;
    },
    checkValue(value) {
      return !(value === undefined || value === null || value === "");
    },
    pickHighestDti (dtiData) {
      let valueDti = 0;
      for (const value of Object.entries(dtiData)) {
        for (const valueElement of value) {
          if (valueDti < valueElement.value) {
            valueDti = Math.round(valueElement.value);
            this.estimatedDti = valueElement;
          }
        }
      }
    },
    saveIncome () {
      const incomeObject = {
        typ: "hlavny",
        druh: "prijem_z_sro",
        druh_prijmu_szco: "sro",
        zamestnanie_od: this.form.podnikanieOd,
        zdanenie_minuly_rok: this.form.zdanenie_minuly_rok,
        minulorocny_obrat: this.form.minulorocny_obrat,
        zisk_sro_vyplateny: this.form.zisk_sro_vyplateny,
        zisk_sro: this.form.zisk_sro,
        podiel_v_sro: this.form.podiel_v_sro,
        zamestnavatel: this.employerDetail.id,
        zamestnavatel_odvetvie: this.form.odvetvie,
        komora: this.form.komora,
      };
      if (this.income) {
        incomeObject.id = this.income.id;
      }
      // console.log(incomeObject);
      this.$emit("income-saved", {incomeInfo: incomeObject});
    },
  },
  data: () => ({
    booleanForm: [
      {
        display_name: "áno",
        value: true,
      },
      {
        display_name: "nie",
        value: false,
      },
    ],
    dtiLoading: false,
    ico: null,
    estimatedDti: null,
    form: {
      podnikanieOd: null,
      zdanenie_minuly_rok: null,
      minulorocny_obrat: null,
      zisk_sro_vyplateny: null,
      zisk_sro: null,
      podiel_v_sro: null,
      zamestnavatel: null,
      odvetvie: null,
      komora: null,
    },
    employerDetail: {
      name: null,
      datum_zalozenia: null,
      slovensko_digital_response: {
        formatted_address: null,
      },
    },
  }),
};
</script>

<style scoped lang="scss">
.error-text {
  font-size: 11px;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
