<template>
  <div class="d-flex justify-content-between">
    <div class="closebtn-broker-app pointer" @click="restoreApplication()">
      <img src="@/images/refresh-icon.svg" loading="lazy" width="10px" alt=""/>
    </div>
    <div class="ziadost-icon justify-content-start">
      <img
          v-if="object.bank"
          :src="object.bank.logo_square"
          loading="lazy"
          alt=""
          class="bank-logo"
      />
      <div class="text-part mr-3">
        <div class="bold black--text">{{ object.suma_bezucel + object.suma_refinancovanie + object.suma_nadobudnutie | dotTheAmount }} €</div>
        <div class="bold black--text font-size-12">{{ object.splatka | toFloor | dotTheAmount }} €</div>
      </div>
    </div>
    <!-- <div class="bold black--text font-size-14">
      1.9
      <span class="upper-index font-size-10">%</span>
    </div> -->
  </div>
</template>

<script>
import imagesConvert from "@/mixins/imagesConvert";

export default {
  name: "MortgageApplicationsObjects",
  props: {
    object: Object,
  },
  mixins: [
    imagesConvert,
  ],
  methods: {
    restoreApplication() {
      this.$emit("restore", this.object);
    },
  },
}
</script>

<style scoped>

</style>