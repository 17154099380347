<template>
  <div>
    <v-scroll-x-reverse-transition tag="div" group>
      <component
        v-for="(item, index) in popupOrder"
        :key="index"
        :define="define"
        :extra="item.extra"
        :type="item.type"
        :payload="item.payload"
        :is="item.component"
        :meta-data="metaData"
        :style="getStyle(index, item.type)"
        @set-meta="metaData = $event"
      />
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MortgagePickerPopUp from "./Popups/MortgagePicker/MortgagePickerPopUp";
import NotificationPopUp from "./Popups/NotificationsManager/NotificationPopUp";

import MortgageAddPopUp from "./Popups/MortgageAdd/MortgageAddPopUp";

import BrokerPickerPopUp from "./Popups/BrokerPicker/BrokerPickerPopUp";
import MortgageApplicationUploadPopUp from "./Popups/MortgageApplicationStageChanges/MortgageApplicationUploadPopUp";
import MortgageApplicationApprovalPopup
  from "./Popups/MortgageApplicationStageChanges/MortgageApplicationApprovalPopup";
import MortgageApplicationDrawdownPopup
  from "./Popups/MortgageApplicationStageChanges/MortgageApplicationDrawdownPopup";
import MortgageApplicationStoppedPopup from "./Popups/MortgageApplicationStageChanges/MortgageApplicationStoppedPopup";
import MortgageDocumentsSendPopUp from "./Popups/MortgageDocumentsSend/MortgageDocumentsSendPopUp";
import OrderPropertyReportPopUp from "./Popups/OrderPropertyReport/OrderPropertyReportPopUp";
import DocumentCloudPopUp from "./Popups/DocumentCloud/DocumentCloudPopUp";

import UserManagerPopUp from "./Popups/UserManager/UserManagerPopUp";
import ClientChatPopUp from "./Popups/ClientChat/ClientChatPopUp";
import ClientPersonalInfoPopUp from "./Popups/ClientPersonalInfo/ClientPersonalInfoPopUp";

import ClientVerificationPicker from "./Popups/ClientVerification/ClientVerificationPicker";
import ClientVerificationPopUp from "./Popups/ClientVerification/ClientVerificationPopUp";
import ApplicationMissingInfoPopUp from "./Popups/ApplicationMissingInfo/ApplicationMissingInfoPopUp"

import ClientEventPopUp from "./Popups/ClientMortgageEvents/ClientEventPopUp";

import MaturityFixationChoosePopUp from "./Popups/MaturityFixationChoose/MaturityFixationChoosePopUp";
import ClientPropertyPicker from "./Popups/ClientProperty/ClientPropertyPicker";
import ClientPropertyPopUp from "./Popups/ClientProperty/ClientPropertyPopUp";
import ClientDebtPicker from "./Popups/ClientDebt/ClientDebtPicker";
import ClientDebtPopUp from "./Popups/ClientDebt/ClientDebtPopUp";
import ClientIncomePicker from "./Popups/ClientIncomes/ClientIncomePicker";
import ClientEmploymentDietyPopUp from "./Popups/ClientEmploymentDiety/ClientEmploymentDietyPopUp";
import ClientEmploymentRestPopUp from "./Popups/ClientEmploymentRest/ClientEmploymentRestPopUp";
import ClientIncomePopUp from "./Popups/ClientIncomes/ClientIncomePopUp";
import ClientBonitaPopUp from "./Popups/ClientBonita/ClientBonitaPopUp";
import ClientBonitaPicker from "./Popups/ClientBonita/ClientBonitaPicker";
import ClientAddPopUp from "./Popups/ClientAdd/ClientAddPopUp";
import CalculationsHistoryPopUp from "./Popups/CalculationsHistory/CalculationsHistoryPopUp";

import { mapGetters } from "vuex";
import BasicClientInformationPopUp from "./Popups/ClientPicker/BasicClientInformationPopUp";
import ApplicationGeneratorPopUp from "./Popups/ApplicationGenerator/ApplicationGeneratorPopUp";
import MortgageAmountsPopUp from "./Popups/MortgageAmounts/MortgageAmountsPopUp";
import TestimonialManagerPopUp from "./Popups/TestimonialManager/TestimonialManagerPopUp";
import ExpertPickerPopUp from "./Popups/ExpertPicker/ExpertPickerPopUp";
import TestimonialInvoicePopUp from "./Popups/TestimonialInvoice/TestimonialInvoicePopUp";
import TestimonialDeadlinePopUp from "./Popups/TestimonialDeadline/TestimonialDeadlinePopUp";
import TestimonialOverviewPopUp from "./Popups/TestimonialOverview/TestimonialOverviewPopUp";
import ApplicationHelpPopUp from "./Popups/ApplicationHelp/ApplicationHelpPopUp";
import posudokOrderPopup from "./Popups/ApplicationHelp/posudokOrderPopup";
import QuickResponseChatPopUp from "./Popups/QuickResponseChat/QuickResponseChatPopUp";
import ClientChatSharePopUp from "./Popups/ClientChatShare/ClientChatSharePopUp.vue";
import ExtendedViewPopUp from "./Popups/ClientMortgageEvents/ExtendedViewPopUp.vue";
import DocumentCloudFileUpload from "./Popups/DocumentCloud/DocumentCloudFileUpload.vue";

// banky metodicke karty

import slsp_prijmy from "./Popups/ApplicationHelp/slsp_prijmy.vue";
import tatra_prijmy from "./Popups/ApplicationHelp/tatra_prijmy.vue";
import mbank_prijmy from "./Popups/ApplicationHelp/mbank_prijmy.vue";
import unicredit_prijmy from "./Popups/ApplicationHelp/unicredit_prijmy.vue";
import bank365_prijmy from "./Popups/ApplicationHelp/bank365_prijmy.vue";
import prima_prijmy from "./Popups/ApplicationHelp/prima_prijmy.vue";
import csob_prijmy from "./Popups/ApplicationHelp/csob_prijmy.vue";
import vub_prijmy from "./Popups/ApplicationHelp/vub_prijmy.vue";



export default {
  name: "PopupHandler",
  components: {
    DocumentCloudFileUpload,
    ExtendedViewPopUp,
    ClientChatSharePopUp,
    QuickResponseChatPopUp,
    ApplicationHelpPopUp,
    posudokOrderPopup,
    TestimonialOverviewPopUp,
    TestimonialDeadlinePopUp,
    TestimonialInvoicePopUp,
    ExpertPickerPopUp,
    TestimonialManagerPopUp,
    MortgageAmountsPopUp,
    ApplicationGeneratorPopUp,
    BasicClientInformationPopUp,
    MortgagePickerPopUp,
    NotificationPopUp,
    //
    MortgageAddPopUp,
    //
    OrderPropertyReportPopUp,
    DocumentCloudPopUp,
    //,
    MortgageApplicationUploadPopUp,
    MortgageApplicationStoppedPopup,
    MortgageApplicationApprovalPopup,
    MortgageApplicationDrawdownPopup,
    MortgageDocumentsSendPopUp,
    BrokerPickerPopUp,
    //
    UserManagerPopUp,
    ClientChatPopUp,
    ClientPersonalInfoPopUp,
    ApplicationMissingInfoPopUp,
    //
    ClientVerificationPicker,
    ClientVerificationPopUp,
    MaturityFixationChoosePopUp,
    //
    ClientEventPopUp,
    //
    ClientPropertyPicker,
    ClientPropertyPopUp,
    ClientDebtPicker,
    ClientDebtPopUp,
    ClientEmploymentDietyPopUp,
    ClientEmploymentRestPopUp,
    ClientIncomePicker,
    ClientIncomePopUp,
    ClientBonitaPicker,
    ClientBonitaPopUp,
    ClientAddPopUp,
    CalculationsHistoryPopUp,
    //
    slsp_prijmy,
    vub_prijmy,
    tatra_prijmy,
    csob_prijmy,
    prima_prijmy,
    mbank_prijmy,
    unicredit_prijmy,
    bank365_prijmy
  },
  data: () => ({
    define: false,
    viewPortWidth: null,
    metaData: null,
    popupOrder: [],
  }),
  computed: {
    ...mapGetters({
      popupLengthGetter: "popupManagerState/getLengthOfPopupOrder",
    }),
  },
  watch: {
    popupLengthGetter (popUpOrderLength) {
      this.popupOrder = this.$store.getters["popupManagerState/getPopupOrder"];
      if (popUpOrderLength === 0) {
        this.metaData = null;
      }
    },
  },
  created () {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 27) {
        this.$store.commit("popupManagerState/resetPopupOrder");
      }
    });
  },
  methods: {
    getStyle (index, type) {
      if (type === "full-width") {
        return "width: 90%; position: fixed!important;"
      }
      if (type === "define") {
        this.define = true;
        this.viewPortWidth = window.innerWidth;
        return "width: 100%; position: fixed!important;"
      }
      if (index === 0) {
        this.viewPortWidth = window.innerWidth;
      }
      let style = null;
      if (this.viewPortWidth > 992) {
        style = "width: " + (35 - index) + "%;"
      } else if (this.viewPortWidth > 768) {
        style = "width: " + (40 - index) + "%;"
      } else if (this.viewPortWidth > 480) {
        style = "width: " + (80 - index) + "%; position: fixed!important;"
      } else if (this.viewPortWidth > 240) {
        style = "width: " + (90 - index) + "%; position: fixed!important;"
      }
      // console.log(style);
      return style;
    },
  },
};
</script>

<style scoped>
</style>
