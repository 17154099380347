<template>
    <div class="navigation-bar">
      <!-- tab výber ponuky -->
      <div
        class="div-block-111"
        :class="[component === 'ApplicationChoice' ? 'active' : '']"
      >
        <div class="d-flex justify-content-center align-items-center pointer"
             @click="navigateToStage('ApplicationChoice')"

        >
          <div
            class="div-block-110"
          >
            <div>1</div>
          </div>
          <div class="bold progressname">VÝBER</div>
        </div>
      </div>
      <!-- tab dokumentacia -->
      <div
        class="div-block-111"
        :class="[component === 'DocumentCompletion' ? 'active' : '']"
      >
        <div class="d-flex justify-content-center align-items-center pointer"
             @click="navigateToStage('DocumentCompletion')">
          <div
            class="div-block-110"
          >
            <div>2</div>
          </div>
          <div class="bold progressname">PODKLADY</div>
        </div>
      </div>
      <!-- tab podpis -->
      <div
        class="div-block-111"
        :class="[component === 'ApplicationSign' ? 'active' : '']"
      >
        <div class="d-flex justify-content-center align-items-center pointer"
             @click="navigateToStage('ApplicationSign')"
        >
          <div
            class="div-block-110"
          >
            <div>3</div>
          </div>
          <div class="bold progressname">PODPIS</div>
        </div>
      </div>
      <!-- tab schvalenie ziadosti -->
      <div
        class="div-block-111"
        :class="[component === 'ApplicationApproval' ? 'active' : '']"
      >
        <div class="d-flex justify-content-center align-items-center pointer"
             @click="navigateToStage('ApplicationApproval')"
        >
          <div
            class="div-block-110"
          >
            <div>4</div>
          </div>
          <div class="bold progressname">SCHVÁLENIE</div>
        </div>
      </div>
      <!-- tab cerpanie -->
      <div
        class="div-block-111"
        :class="[component === 'ApplicationSummary' ? 'active' : '']"
      >
        <div class="d-flex justify-content-center align-items-center pointer"
             @click="navigateToStage('ApplicationSummary')">
          <div
            class="div-block-110"
          >
            <div>5</div>
          </div>
          <div class="bold progressname">ČERPANIE</div>
        </div>
      </div>


      <!-- <div class="d-flex justify-content-end">
        <div
          class="div-block-111 pointer"
          @click="navigateToStage('DocumentCompletion')"
          :class="[component === 'DocumentCompletion' ? 'active' : '']"
        >
          <div class="bold">CLOUD</div>
        </div>
      </div> -->
    </div>
</template>

<script>
export default {
  name: "PrograssBarHypoteky",
  props: {
    stepIndex: Number,
  },
  methods: {
    navigateToStage (component) {
      if (component === "ApplicationSummary") {
        const applications = this.$store.getters["mortgageDetailState/getApplications"];
        for (const application of applications) {
          if (application.stage === "cerpana" || application.stage === "nacerpana") {
            this.component = component;
            this.$emit('switch-to', component);
            return;
          }
        }
        this.$store.commit("appState/setSuccessMessage", {
          type: "error",
          text: "Pre otvorenie stepu čerpanie je potrebné ožnačiť žiadosť hypotéky ako čerpaná"
        })
        return;
      }
      this.component = component;
      this.$emit('switch-to', component)
    }
  },
  created () {
    this.navigateToStage(this.component);
  },
  data: () => ({
    component: "ApplicationChoice",
  }),
};
</script>

<style lang="scss" scoped>
</style>
