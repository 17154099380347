<template>
  <div
    id="w-node-_33df7b64-d3e3-9bb8-c9ea-7c0b79a8b3cc-5bbc1f5a"
    class="div-block-102"
  >
    <div class="div-block-104">
      <img
        src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f3a_property%20icon.png"
        loading="lazy"
        alt=""
        class="image-10"
      />
      <div class="div-block-103 aside">
        <div class="bold">Zakladané nehnut.</div>
      </div>
    </div>
    <div
      class="input-block contrast p-relative"
      v-for="(property, key) in foundedProperties"
      :key="key"
    >
      <div class="closebtn-broker-app pointer" @click="remove(property.id)">
        <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
      </div>
      <div class="closebtn-broker-app pointer edit" @click="finishProperty(property)">
        <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/64cb50251cbbf5e263940bb6_edit%20icon%20simple.svg"/>
      </div>
      <div class="div-block-101">
        {{ getAddressModified(property) }}
        <v-progress-circular
          v-if="property.loading"
          class="loading-spinner extrasmall"
          indeterminate
        ></v-progress-circular>
        <div class="bold" v-else>{{ property.hodnota | dotTheAmount }} EUR</div>
      </div>
    </div>
    <client-property-handler
      :rules="['priority']"
      :type="'zakladane_nehnutelnosti'"
      :title="'zakladané NEHNUTEĽNOSTI'"
    />
  </div>
</template>

<script>
import ClientPropertyHandler from "../../../../shared/PopupHandler/Popups/ClientProperty/ClientPropertyHandler";
import { mapGetters } from "vuex";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "FoundedProperty",
  components: {ClientPropertyHandler},
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "mortgageDetailState/getProperties",
    }),
  },
  mixins: [
    propertyFilters,
  ],
  watch: {
    propertiesGetter (newProperties) {
      const deepEqual = require("deep-equal");
      const newFoundedProperties = this.filterFoundedPropertiesById(newProperties);
      if (!deepEqual(this.foundedProperties, newFoundedProperties, true)) {
        this.foundedProperties = newFoundedProperties;
      }
    },
  },
  created () {
    const propertiesObject = this.$store.getters["mortgageDetailState/getProperties"];
    this.foundedProperties = this.filterFoundedPropertiesById(propertiesObject);
    this.foundedPropertiesIds = propertiesObject.foundedPropertiesIds;
  },
  props: {
    mortgageId: Number,
  },
  data: () => ({
    foundedProperties: [],
    properties: [],
  }),
  methods: {
    remove (idOfRemoved) {
      this.foundedPropertiesIds = this.foundedPropertiesIds.filter((id) => id !== idOfRemoved);
      const data = {
        propertyId: idOfRemoved,
        mortgageId: this.mortgageId,
        propertyLabel: "Zakladaná",
        propertyType: "foundedPropertiesIds",
        object: {
          zakladane_nehnutelnosti: this.foundedPropertiesIds,
        }
      };
      // console.log(data);
      this.$store.dispatch("mortgageDetailState/removeProperty", data);
    },
    finishProperty (pProperty) {
      let type = pProperty.child_type;
      if (type === "flat" && pProperty.typ_bytu === "v_rodinnom_dome") {
        type = "flatInHouse";
      } else if (type === "flat" && pProperty.typ_bytu === "v_bytovom_dome") {
        type = "flatInFlat";
      }
      this.propertyTypePicker(type, pProperty);
    },
    propertyTypePicker (type, propertyData = null) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {druh: this.type, child_type: type},
        payload: propertyData,
        component: "ClientPropertyPopUp",
        rules: this.rules,
      });
    },
  },
};
</script>

<style scoped>
  .closebtn-broker-app.pointer.edit{
    right:20px;
  }
</style>
