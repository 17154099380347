<template>
  <div style="position: relative">
    <kanban-board :blocks="applications" :stages="stages" :config="config" @update-block="applicationMoved">
      <div v-for="(stage, index) in stages" :slot="stage" :key="stage" class="light-text small">
        <h2 class="pop-800 blc-color text-uppercase header-custom">
          <h2 class="div-block-110 bordered">
            {{ index + 1 }}
          </h2>
          {{ getLabel(stage) }}
        </h2>
      </div>
      <div v-for="(application, index) in applications" :key="index" :slot="application.id"
           class="ziadost-item-custom pointer position-relative"
           :class="{'editing': isEditable(application)}">
        <div
          class="closebtn-broker-app pointer"
          v-if="show"
          @click="removeApplication(application)"
        >
          <img class="nav-link-icon extrasmall white" :src="application.bank.logo_square"/>
        </div>
        <div
          class="ziadost-top"
        >
          <div class="ziadost-icon">
            <img
              :src="application.bank.logo_square"
              loading="lazy"
              alt=""
              class="bank-logo"
            />
            <div class="text-part">
              <div class="bold">{{ application.suma_nadobudnutie + application.suma_refinancovanie + application.suma_bezucel }} EUR</div>
              <div>{{ application.splatka }} EUR</div>
              <div
                class="text-cta pointer"
                v-if="application.status === 'schvalena' && application.stage === 'schvalena'"
                @click="applicationMoved(application.id, 'cerpana', index)"
              >
                ČERPAŤ
              </div>
            </div>
          </div>
          <div class="bold bl-color">
            {{ application.sadzba_schvalena !== null ? application.sadzba_schvalena : application.sadzba_pozadovana }}
            <span class="upper-index">%</span>
          </div>
        </div>
        <div class="ziadost-footer" v-if="application.stage === 'prerusena'">
          <img src="@/images/application-check-button.svg" width="50" alt="">
          <span class="text">
            {{ application.dovod_prerusenia }}
          </span>
        </div>
      </div>
    </kanban-board>
    <objects-remove-pop-up
      :objects-component="'MortgageApplicationsObjects'"
      @restore="restoreApplication($event)"
      :show="show"
      :objects="getRemovedApplications()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ObjectsRemovePopUp from "@/components/shared/PopupHandler/Popups/ObjectsRemove/ObjectsRemovePopUp.vue";
import imagesConvert from "@/mixins/imagesConvert";

export default {
  name: "MortgageApplicationsApprovalWrapper",
  components: {
    ObjectsRemovePopUp,
  },
  computed: {
    ...mapGetters({
      removalPopupGetter: "popupManagerState/getMortgageApplicationsRemovePopup",
      popupSuccessGetter: "popupManagerState/getPopupSuccessResult",
    }),
  },
  mixins: [
    imagesConvert
  ],
  props: {
    applicationsObjects: Array,
  },
  watch: {
    removalPopupGetter (value) {
      this.show = value;
    },
    applicationsObjects (applicationsObjects) {
      const applications = JSON.parse(JSON.stringify(applicationsObjects))
      this.applications = [];
      for (const application of applications) {
        if (["cerpana", "vybrana", "nacerpana"].includes(application.stage)) {
          application.status = "schvalena";
          application.notMovable = false;
          this.isDrawdownPresent = true;
        } else if (application.stage === "zrusena") {
          application.status = application.stage_old;
          application.notMovable = true;
        } else {
          application.status = application.stage;
          application.notMovable = false;
        }
        this.applications.push(application);
      }
    },
    async popupSuccessGetter (successInfo) {
      if (successInfo.status === null) return;
      if (successInfo.status === true) {
        await this.changeStatusOfEdited(successInfo);
      } else if (successInfo.status === false) {
        this.returnApplicationBack(this.applicationEditObject.id);
      }
      this.applicationEdit = false;
      this.applicationEditObject = null;
      this.$store.commit("popupManagerState/setPopupSuccessResult", {status: null});
    },
  },
  created () {
    this.role = this.$store.getters["userState/getRole"];
    const applications = JSON.parse(JSON.stringify(this.applicationsObjects))
    for (const application of applications) {
      if (["cerpana", "vybrana", "nacerpana"].includes(application.stage)) {
        application.status = "schvalena";
        application.notMovable = false;
        this.isDrawdownPresent = true;
      } else if (application.stage === "zrusena") {
        application.status = application.stage_old;
        application.notMovable = true;
      } else {
        application.status = application.stage;
        application.notMovable = false;
      }
      this.applications.push(application);
    }
  },
  methods: {
    isEditable(application) {
      if (this.applicationEdit) {
        return true;
      } else if (this.isDrawdownPresent && !this.isDrawDawn(application)) {
        return true;
      } else if (application.notMovable) {
        return true;
      }
      return false;
    },
    isDrawDawn(application) {
      return ["cerpana", "vybrana", "nacerpana"].includes(application.stage);
    },
    restoreApplication (application) {
      const restoredApplication = {
        ...application,
        stage_old: application.stage,
        stage: application.stage_old,
      }
      this.$store.dispatch("mortgageDetailState/restoreApplication", {restoredApplication});
    },
    removeApplication (application) {
      const removedApplication = {
        ...application,
        stage_old: application.stage,
        stage: "zrusena",
      }
      this.$store.dispatch("mortgageDetailState/removeApplication", {removedApplication});
    },
    getRemovedApplications () {
      return this.applications.filter((application) => application.stage === "zrusena");
    },
    getLabel (type) {
      return this.stageLabel[type];
    },
    async changeStatusOfEdited (successInfo) {
      const mortgageMovedInGrid = this.applications.find((mortgage) => mortgage.id === parseInt(this.applicationEditObject.id));
      mortgageMovedInGrid.status = this.applicationEditObject.newStatus;
      await this.$store.dispatch("mortgageDetailState/setMortgageApplicationStage", {id: parseInt(this.applicationEditObject.id), newStage: successInfo.stage});
      await this.$store.dispatch("mortgageDetailState/patchMortgageApplication", {id: parseInt(this.applicationEditObject.id), applicationPatchData: successInfo.form});
      // const indexOfMortgageMoved = this.applications.indexOf(mortgageMovedInGrid);
      // this.applications.splice(indexOfMortgageMoved, 1);
      // setTimeout(() => {
      //   this.applications.push(mortgageMovedInGrid);
      // }, 1);
    },
    returnApplicationBack (id) {
      const mortgageMovedInGrid = this.applications.find((mortgage) => mortgage.id === parseInt(id));
      const indexOfMortgageMoved = this.applications.indexOf(mortgageMovedInGrid);
      this.applications.splice(indexOfMortgageMoved, 1);
      setTimeout(() => {
        this.applications.push(mortgageMovedInGrid);
        this.applicationEdit = false;
        this.applicationEditObject = null;
      }, 1);
    },
    applicationMoved (id, newStatus, index) {
      const oldStatus = (this.applications.find((mortgage) => mortgage.id === parseInt(id))).status;
      this.applicationEdit = true;
      this.applicationEditObject = {
        id: id,
        newStatus: newStatus,
      }
      if (newStatus === "podana_v_banke" && oldStatus === "podpisana") {
        this.changeStateOfApplication()
        // this.openSignedStatusPopup();
        return;
      } else if (newStatus === "prerusena" && oldStatus === "podana_v_banke") {
        this.openStoppedStatusPopup();
        return;
      } else if (newStatus === "schvalena" && (oldStatus === "podana_v_banke" || oldStatus === "prerusena")) {
        this.openApprovedStatusPopup(this.applications[index]);
        return;
      } else if (newStatus === "cerpana" && oldStatus === "schvalena") {
        this.openDrawdownStatusPopup(this.applications[index]);
        return;
      }
      this.returnApplicationBack(id);
    },
    async changeStateOfApplication() {
      await this.$store.dispatch("mortgageDetailState/setMortgageApplicationStage", {id: parseInt(this.applicationEditObject.id), newStage: this.applicationEditObject.newStatus});
      if (this.applicationEditObject.newStatus !== "podana_v_banke") {
        this.applicationEdit = false;
        return;
      }
      const mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
      const mortgageObject = {
        id: mortgage.id,
        object: {
          stage: "schvalovanie",
          stage_old: mortgage.stage,
        }
      }
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.applicationEdit = false;
    },
    openSignedStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationUploadPopUp",
        rules: ['single'],
      });
    },
    openStoppedStatusPopup () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null, // {id, newStatus}
        component: "MortgageApplicationStoppedPopup",
        rules: ['single'],
      });
    },
    openApprovedStatusPopup (applicationData) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: applicationData, // {id, newStatus}
        component: "MortgageApplicationApprovalPopup",
        rules: ['single'],
      });
    },
    openDrawdownStatusPopup (applicationData) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: applicationData, // {id, newStatus}
        component: "MortgageApplicationDrawdownPopup",
        rules: ['single'],
      });
    },
  },
  data: () => ({
    show: false,
    stageLabel: {
      podpisana: "Podpísaná",
      podana_v_banke: "Podaná",
      prerusena: "Prerušená",
      schvalena: "Schválená",
    },
    stages: ["podpisana", "podana_v_banke", "prerusena", "schvalena"],
    applications: [],
    config: {
      moves (el) {
        return !el.children[0].className.includes("editing");
      }
    },
    applicationEdit: false,
    isDrawdownPresent: false,
    applicationEditObject: null,
    role: null,
  }),
}
</script>

<style lang="scss">
@import "node_modules/vue-kanban/src/assets/kanban.scss";

.drag-list{
  display: flex !important;
  flex-direction: row !important;
  height: 70vh;
}

.drag-item {
  display: flex;
  justify-content: center;
  height: auto;
  background: none;
  margin: 10px 0;
  padding: 0;
}

.drag-container {
  max-width: none;
  margin: 0 40px;
  overflow-x: auto;
}

.drag-column-header {
  display: block;
  padding: 0;
  text-align: center;
}

.drag-column {
  min-width: 250px;

  & h2 {
    text-transform: none;
  }

  background: none;
}
</style>

<style lang="scss" scoped>
.ziadost-item-custom {
  padding: 0;
}

.header-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editing {
  cursor: not-allowed !important;
  opacity: 0.5;
}

</style>
