<template>
  <div class="popup-form">
    <div class="content-form-popup">
      <div class="header-text">
        <div class="back-button-wrapper pointer" @click="closePopup">
          <img
            class="nav-link-icon filter-primary"
            src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f22_icons8-cancel.svg"
            alt=""
          />
        </div>
      </div>
      
      <iframe
        class="embedded-iframe"
        src="https://bankari-5047.slite.page/p/F0kk9_6OUgXoCX/Metodicka-karta-VUB"
        frameborder="0"
        allowfullscreen
    ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "vub_prijmy",
  props: {
    type: String,
  },
  methods: {
    closePopup () {
      this.$store.commit("popupManagerState/popItemFromPopupOrder");
    },
  },
}
</script>

<style lang="scss" scoped>
.header-popup-wrapper {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icontext {
  line-height: 12px;
  font-size: 17px;
}

.content-panel{
    padding: 40px;
  }

  .embedded-iframe {
    width: auto;
    height: 85vh;
    border-radius: 0px;
    overflow: hidden;
    margin: -20px;
  }

  @media screen and (max-width: 767px) {
    .content-panel{
      padding: 20px;
    }
    .embedded-iframe {
      margin: -20px;
    }
  }

</style>
