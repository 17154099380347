<template>
  <form @submit.prevent="saveDebt" id="outcome-submit">
    <div class="form-2">
      <div class="input-group">
        <label for="cis-zmluy" class="field-label">Č. zmluvy</label
        ><input
          v-model.number="form.cislo_uveru"
          type="text"
          class="text-input w-input"
          maxlength="256"
          name="cis-zmulvy"
          data-name="cis-zmluvy"
          placeholder=""
          id="cis-zmluy"
        />
      </div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="setDropdownValue($event, 'banka')"
          :id="'mortgage_banka'"
          :init-value="form.banka"
          :options="bankChoices"
          normal
          :name="'Spoločnosť'"
        />
      </div>

      <!-- povodny kod na stresovanie splatky na fe -->
      <!-- <div class="input-group">
        <label class="field-label">Splátka</label
        ><input
        type="number"
        step="0.01"
        class="text-input w-input"
        maxlength="256"
        name="Splatka"
        data-name="Splátka"
        placeholder=""
        v-model.number="splatka"
        @input="handleSplatkaChange"
      />
      </div> -->
      <div class="input-group">
        <label for="Spl-tka" class="field-label">Splátka (automaticky sa stresuje)</label
        ><input
        v-model.number="form.vyska_splatky"
        type="number"
        step="0.01"
        class="text-input w-input"
        maxlength="256"
        name="Splatka"
        data-name="Splátka"
        placeholder=""
        id="Spl-tka"
      />
      </div>
      <div class="input-group">
        <label for="den-splatky" class="field-label"
        >Den splátky</label
        ><input
        v-model.number="form.den_splatky"
        type="number"
        class="text-input w-input"
        maxlength="256"
        min="1"
        max="32"
        name="den-splatky"
        data-name="den-splatky"
        placeholder=""
        id="den-splatky"
      />
      </div>
      <div class="input-group">
        <label for="Zostatok" class="field-label"
        >Zostatok</label
        ><input
        v-model.number="form.zostatok"
        type="number"
        step="0.01"
        class="text-input w-input"
        maxlength="256"
        name="Zostatok"
        data-name="Zostatok"
        placeholder=""
        id="Zostatok"
      />
      </div>
      <div class="input-group">
        <label for="Fix-cia" class="field-label">Fixácia</label
        ><input
        v-model="form.fixacia"
        type="text"
        class="text-input w-input"
        maxlength="256"
        name="Fixacia"
        min="0"
        data-name="Fixacia"
        id="Fix-cia"
      />
      </div>
      <div class="splitter noselect">
        ak zostatok neviete, po zadaní parametrov úveru <br/>
        nižšie ho pre Vás spočítame
      </div>
      <div class="input-group">
        <label for="pocietoc-obj" class="field-label"
        >Počiatočný objem<strong></strong></label
        ><input
        @change="calculateRest"
        v-model.number="additionalBalance.suma"
        type="number"
        step="0.01"
        class="text-input w-input"
        maxlength="256"
        name="pocietoc-obj"
        data-name="pocietoc-obj"
        placeholder=""
        id="pocietoc-obj"
      />
      </div>
      <div class="input-group">
        <label for="Sadzba" class="field-label"
        >Sadzba v %<strong></strong></label
        ><input
        @change="calculateRest"
        v-model.number="additionalBalance.sadzba"
        type="number"
        class="text-input w-input"
        maxlength="256"
        name="Sadzba"
        step="0.01"
        data-name="Sadzba"
        placeholder=""
        id="Sadzba"
      />
      </div>
      <span
        class="clientError"
        v-if="!$v.additionalBalance.sadzba.maxDigits"
      >
        Maximálny počet cifier je {{$v.additionalBalance.sadzba.$params.maxDigits["upperLimit"]}}
      </span>
      <span
        class="clientError"
        v-if="!$v.additionalBalance.sadzba.maxInteger"
      >
        Maximálny počet cifier pred bodkou je {{$v.additionalBalance.sadzba.$params.maxInteger["maxInteger"]}}
      </span>
      <span
        class="clientError"
        v-if="!$v.additionalBalance.sadzba.maxDecimal"
      >
        Maximálny počet cifier za bodkou je {{$v.additionalBalance.sadzba.$params.maxDecimal["maxDecimal"]}}
      </span>
      <div
        class="input-group"
      >
        <label for="zaciatok-hypoteka" class="field-label"
        >Začiatok splácania</label
        ><input
        @change="calculateRest"
        v-model="additionalBalance.zaciatok"
        type="date"
        :max="todayLong"
        class="text-input w-input date-input"
        maxlength="256"
        name="zaciatok-hypoteka"
        data-name="zaciatok-hypoteka"
        id="zaciatok-hypoteka"
      />
      </div>
      <div class="input-group">
        <label for="splatnost-hypoteka" class="field-label"
        >Splatnosť hypotéky v <span class="bl-color" @click="changeMaturityMode">{{ maturityYears ? "rokoch" : "mesiacoch"}}</span></label
        ><input
        @keydown.tab.prevent
        @change="calculateRest"
        v-model.number="additionalBalance.maturity"
        type="number"
        min="0"
        step=".01"
        class="text-input w-input"
        maxlength="256"
        name="splatnost-hypoteka"
        data-name="splatnost-hypoteka"
        id="splatnost-hypoteka"
      />
      </div>
    </div>
  </form>
</template>

<script>
import { decimal, required } from 'vuelidate/lib/validators'
import { maxDigits, maxDecimal, maxInteger } from "@/utils/numberValidators";
import DropdownSelect from "../../../DropdownSelect";
import { getLoanData } from "@/services/brokerService";

export default {
  name: "MortgageOutcome",
  components: {DropdownSelect},
  props: {
    debt: [Object, null],
    banks: [Array, Object],
  },
  data: () => ({
    bankChoices: [],
    splatka: null, // New reactive property for splatka
    maturityYears: true,
    todayLong: null,
    additionalBalance: {
      suma: null,
      sadzba: null,
      zaciatok: null,
      maturity: null,
    },
    form: {
      banka: null,
      zostatok: null,
      vyska_splatky: null,
      den_splatky: null,
      fixacia: null,
      cislo_uveru: null,
    },
  }),
  created () {
    for (const bank of this.banks) {
      this.bankChoices.push({
        display_name: bank.nazov,
        value: bank.id,
      });
    }
    if (this.debt) {
      this.additionalBalance.suma = this.debt.povodna_suma;
      this.form.banka = this.debt.banka;
      this.form.zostatok = this.debt.zostatok;
      this.form.vyska_splatky = this.debt.vyska_splatky;
      this.form.den_splatky = this.debt.den_splatky;
      this.form.fixacia = this.debt.fixacia;
      this.form.cislo_uveru = this.debt.cislo_uveru;
      this.additionalBalance.suma = this.debt.povodna_suma;
      this.additionalBalance.sadzba = this.debt.sadzba;
      this.additionalBalance.zaciatok = this.debt.zaciatok;
      this.additionalBalance.maturity = this.convertFromEndDate(this.debt.zaciatok, this.debt.splatnost);
    }
    const d = new Date();
    this.todayLong = [this.addZero(d.getFullYear()), this.addZero(d.getMonth() + 1), this.addZero(d.getDate())].join('-');
  
    if (this.form.vyska_splatky) {
      this.splatka = (this.form.vyska_splatky / 1.3).toFixed(0); // Prefill with a calculated value
    }
  
  },


  methods: {


    // po interakcii s inpotom sa stresovala splatka na FE
    // handleSplatkaChange(event) {
    //   const splatkaValue = parseFloat(event.target.value);
    //   if (!isNaN(splatkaValue)) {
    //     // Multiply the value of splatka by 3 and set it in form.vyska_splatky
    //     this.form.vyska_splatky = Math.round(splatkaValue * 1.3);
    //   } else {
    //     // If invalid, reset or handle appropriately
    //     this.form.vyska_splatky = null;
    //   }
    // },

    addZero(value) {
      if (value < 10) {
        return '0' + value;
      }
      return value;
    },
    convertFromEndDate(startDate, endDate) {
      const startMilis = new Date(startDate).getTime();
      const endMilis = new Date(endDate).getTime();
      const milisDiff = endMilis - startMilis;
      const monthsDiff = milisDiff /  2592000000;
      this.maturityYears = false;
      return Math.floor(monthsDiff);
    },
    changeMaturityMode() {
      this.maturityYears = !this.maturityYears;
      
      if (this.maturityYears) {
        const years = this.additionalBalance.maturity / 12;
        this.additionalBalance.maturity = Math.floor(years); // Use Math.floor to ensure an integer value
      } else {
        this.additionalBalance.maturity *= 12; // Convert years to months by multiplying by 12
      }
    },
    async calculateRest () {
      if (this.$v.$invalid) return;
      const loanMeta = {
        suma: this.additionalBalance.suma,
        sadzba: this.additionalBalance.sadzba,
        zaciatok: this.additionalBalance.zaciatok,
        mesiace: Math.floor(this.maturityYears ? this.additionalBalance.maturity : this.additionalBalance.maturity),
        den_splatky: 1,
      };
      const loanData = await getLoanData(loanMeta);
      this.form.zostatok = loanData.zostatok.toFixed(0);
      this.$store.commit("appState/setSuccessMessage", {
        text: "Zostatok bol úspešne vypočítaný",
        type: "success"
      });
    },
    // translateDateFromDbValue (dateToTransform) {
    //   if (!dateToTransform) return null;
    //   let dateArray = dateToTransform.split("-");
    //   dateArray.pop();
    //   return dateArray.join("-");
    // },
    // transformDateToDbValue (dateToTransform) {
    //   if (!dateToTransform || dateToTransform === "") return null;
    //   return dateToTransform + "-01";
    // },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
    saveDebt () {
      let debtObject = {
        id: this.debt ? this.debt.id : null,
        banka: this.form.banka,
        zostatok: this.form.zostatok,
        vyska_splatky: this.form.vyska_splatky,
        den_splatky: this.form.den_splatky,
        fixacia: this.form.fixacia,
        cislo_uveru: this.form.cislo_uveru,
        povodna_suma: this.additionalBalance.suma,
        sadzba: this.additionalBalance.sadzba,
        zaciatok: this.additionalBalance.zaciatok,
        splatnost: this.calculateEndDate(this.maturityYears ? this.additionalBalance.maturity : this.additionalBalance.maturity / 12),
      };

      if (this.form.zostatok) {
        debtObject.zostatok = this.form.zostatok;
      } else {
        // If the zostatok is empty, set povodna_suma as zostatok
        debtObject.zostatok = this.additionalBalance.suma;
      }

      // console.log(debtObject);
      this.$emit("debt-saved", debtObject);
    },
    calculateEndDate(years) {
      const yearsMilis = years * 31556952000;
      const startMilis = new Date(this.additionalBalance.zaciatok).getTime();
      const milisDif = yearsMilis + startMilis;
      let endDate = new Date();
      endDate.setTime(milisDif);
      endDate = endDate.toLocaleDateString('zh-Hans-CN', {
        year: 'numeric', month: 'numeric', day: 'numeric'
      });
      endDate = endDate.split("/").join("-");
      return endDate;
    }
  },
  validations () {
    return {
      additionalBalance: {
        sadzba: {
          decimal,
          required,
          maxDigits: maxDigits(5),
          maxDecimal: maxDecimal(2),
          maxInteger: maxInteger(3),
        },
        maturity: {required},
        suma: {required},
        zaciatok: {required}
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
