<template>
  <div>
    <div class="d-flex justify-content-between align-center">
      <div>
        Príjem <span class="pop-800">{{ summaryData.clientInfo.type.toLowerCase() }}a</span> zo <span class="pop-800">živnosti</span>
      </div>
      <div>
        <img
          src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f28_icons8-collapse-arrow.svg"
          class="activate-button nav-link-icon filter-primary"
          :class="{'rotate': opened}"
          @click="opened = !opened"
          alt=""
        />
      </div>
    </div>
    <v-expand-transition class="summary-items-transition">
      <div class="summary-items-wrapper" v-if="opened">
        <text-value-holder :label="'typ príjmu'" :value="summaryData.typ"/>
        <text-value-holder :label="'druh príjmu'" :value="'Živnosť'"/>
        <text-value-holder :label="'mesačný príjem'" :value="summaryData.dti.extra.prijem_mo"/>
        <text-value-holder :label="'obchodný názov'" :value="summaryData.zamestnavatel.name"/>
        <text-value-holder :label="'ičo'" :value="summaryData.zamestnavatel.ico"/>
        <text-value-holder :label="'obec'" :value="summaryData.zamestnavatel.address_city"/>
        <text-value-holder :label="'ulica'" :value="summaryData.zamestnavatel.address_street"/>
        <text-value-holder :label="'súpisné číslo'" :value="summaryData.zamestnavatel.address_popisne"/>
        <text-value-holder :label="'orientačné číslo'" :value="summaryData.zamestnavatel.address_number"/>
        <text-value-holder :label="'PSČ'" :value="summaryData.zamestnavatel.address_zip"/>
        <text-value-holder :label="'email. zamestn'" :value="summaryData.zamestnavatel.email"/>
        <text-value-holder :label="'telefon zamestn'" :value="summaryData.zamestnavatel.telefon"/>
        <text-value-holder :label="'odvetvie'" :value="summaryData.zamestnavatel_odvetvie"/>
        <text-value-holder :label="'komora'" :value="summaryData.komora"/>
        <text-value-holder :label="'podnikám od'" :value="transformDate(summaryData.zamestnanie_od)"/>
        <text-value-holder :label="'základ z dane z príjmu'" :value="summaryData?.taxBase"/>
        <text-value-holder :label="'daň z príjmu'" :value="summaryData.dan_z_prijmu"/>
        <text-value-holder :label="'obrat'" :value="summaryData.minulorocny_obrat"/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import TextValueHolder from "./TextValueHolder";
import dateConverter from "@/mixins/dateConverter";

export default {
  name: "TradesmanSummary",
  components: {TextValueHolder},
  props: {
    summaryData: [Object, null],
    openedDefault: Boolean,
  },
  mixins: [
    dateConverter
  ],
  created() {
    this.opened = this.openedDefault;
  },
  data: () => ({
    opened: false,
  })
}
</script>

<style scoped>
</style>
