<template>
  <div>
    <div
      class="div-block-102 noselect"
    >
      <div class="div-block-106">
        <range-slider
          :id-not-selected="'not-selected-nadobudnutie'"
          :id-selected="'selected-nadobudnutie'"
          :min="0"
          :max="limit"
          :main-desc="'nadobudnutie'"
          :default-value="amounts.suma_nadobudnutie"
          :twoSideValues="false"
          :description="'Výška úveru na'"
          :unit="'EUR'"
          :value-correction="valueCorrection"
          @value-corrected="valueCorrection = null"
          @change-event="setNadobudnutieAmount($event, true)"
          @index-value-up-click="roundUpLtv($event)"
          @index-value-down-click="roundDownLtv($event)"
          @value-click="openMortgageAmountsEdit()"
          :percentage-show="financedProperties.length !== 0 && foundedProperties.length !== 0"
          :percentage-max="financedPropertiesValue"
        />
      </div>
      <div class="input-block contrast p-relative" v-for="(property, key) in financedProperties" :key="key">
        <div class="closebtn-broker-app pointer" @click="remove(property.id)">
          <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/648b04f9d8e52d9524912f24_icons8-close.svg"/>
        </div>
        <div class="closebtn-broker-app pointer edit" @click="finishProperty(property)">
          <img class="nav-link-icon extrasmall white" src="https://uploads-ssl.webflow.com/648b04f9d8e52d9524912b4a/64cb50251cbbf5e263940bb6_edit%20icon%20simple.svg"/>
        </div>
        <div class="div-block-101">
          {{ getAddressModified(property) }}
          <v-progress-circular
            v-if="property.loading"
            class="loading-spinner extrasmall"
            indeterminate
          ></v-progress-circular>
          <div class="bold" v-else>{{ property.hodnota | dotTheAmount }} EUR</div>
        </div>
      </div>
      <client-property-handler
        :rules="['priority']"
        :type="'financovane_nehnutelnosti'"
        :title="'+ pridať financovanú NEHNUTEĽNOSŤ'"
      />
    </div>
  </div>
</template>

<script>
import RangeSlider from "../../../../shared/RangeSlider";
import ClientPropertyHandler from "../../../../shared/PopupHandler/Popups/ClientProperty/ClientPropertyHandler";
import { mapGetters } from "vuex";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "NadobudnutieWrapper",
  components: {ClientPropertyHandler, RangeSlider},
  computed: {
    ...mapGetters({
      propertiesGetter: "mortgageDetailState/getProperties",
      amountsGetter: "mortgageDetailState/getAmounts",
    }),
  },
  mixins: [
    propertyFilters,
  ],
  props: {
    mortgage: Object,
    totalLimit: Number,
    loanPurpose: [String, null],
  },
  watch: {
    // limit (newLimit) {
    //   console.log(newLimit);
    // },
    propertiesGetter (newProperties) {
      const deepEqual = require("deep-equal");
      const newFinancedProperties = this.filterFinancedPropertiesById(newProperties);
      this.financedPropertiesIds = newProperties.financedPropertiesIds;
      this.foundedProperties = this.filterFoundedPropertiesById(newProperties);
      if (!deepEqual(this.financedProperties, newFinancedProperties, true)) {
        this.financedProperties = newFinancedProperties;
        this.calculateFinancedPropertiesValue(this.financedProperties);
      }
      if (this.financedProperties.length === 0 || this.foundedProperties.length === 0) {
        this.checkLtv = false;
        this.setMaximumAmount();
        return;
      } else {
        this.checkLtv = true;
      }
      this.calculateLvtMax(this.foundedProperties);
      if (!this.verifyLvtBudget(this.value)) this.setNadobudnutieAmount(this.lvtMax, false);
    },
    amountsGetter (newAmounts) {
      this.amounts = newAmounts;
      this.setMaximumAmount();
    },
    totalLimit () {
      this.setMaximumAmount();
    },
  },
  created () {
    const propertiesObject = this.$store.getters["mortgageDetailState/getProperties"];
    this.financedProperties = this.filterFinancedPropertiesById(propertiesObject);
    this.financedPropertiesIds = propertiesObject.financedPropertiesIds;
    this.foundedProperties = this.filterFoundedPropertiesById(propertiesObject);
    this.amounts = this.$store.getters["mortgageDetailState/getAmounts"];
    if (this.financedProperties.length === 0 || this.foundedProperties.length === 0) {
      this.setMaximumAmount();
      return;
    }
    this.checkLtv = true;
    this.calculateFinancedPropertiesValue(this.financedProperties);
    this.calculateLvtMax(this.foundedProperties);
    if (!this.verifyLvtBudget(this.value)) this.setNadobudnutieAmount(this.lvtMax, false);
    if (this.totalLimit === null) return;
    this.setMaximumAmount();
  },
  data: () => ({
    lvtMax: 0,
    checkLtv: false,
    financedProperties: [],
    financedPropertiesValue: null,
    financedPropertiesIds: [],
    foundedProperties: [],
    amounts: null,
    limit: null,
    value: null,
    valueCorrection: null,
  }),
  methods: {
    finishProperty (pProperty) {
      let type = pProperty.child_type;
      if (type === "flat" && pProperty.typ_bytu === "v_rodinnom_dome") {
        type = "flatInHouse";
      } else if (type === "flat" && pProperty.typ_bytu === "v_bytovom_dome") {
        type = "flatInFlat";
      }
      this.propertyTypePicker(type, pProperty);
    },
    propertyTypePicker (type, propertyData = null) {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: {druh: this.type, child_type: type},
        payload: propertyData,
        component: "ClientPropertyPopUp",
        rules: this.rules,
      });
    },
    openMortgageAmountsEdit () {
      this.$store.commit("popupManagerState/pushToPopupOrder", {
        type: null,
        payload: null,
        component: "MortgageAmountsPopUp",
        rules: ['single'],
      });
    },
    verifyLvtBudget (value) {
      const foundedProperties = this.$store.getters["mortgageDetailState/getProperties"].foundedPropertiesIds;
      if (this.financedProperties.length === 0 || foundedProperties.length === 0) return true;
      if (value > this.lvtMax) {
        this.valueCorrection = this.lvtMax;
        this.$store.commit("appState/setSuccessMessage", {
          text: "Nedostatočné LTV",
          type: "error"
        });
        return false;
      }
      return true;
    },
    roundDownLtv (ltv) {
      if (this.value === 0) return;
      let roundedLtv = Math.floor(ltv / 5) * 5;
      if (ltv === roundedLtv) roundedLtv -= 5
      const newValue = Math.floor(this.financedPropertiesValue * (roundedLtv / 100));
      if (!this.verifyLvtBudget(newValue)) {
        return;
      }
      this.setNadobudnutieAmount(newValue, false);
      this.valueCorrection = newValue;
    },
    roundUpLtv (ltv) {
      if (this.value === this.limit) return;
      let roundedLtv = Math.ceil(ltv / 5) * 5;
      if (ltv === roundedLtv) roundedLtv += 5;
      let newValue = Math.ceil(this.financedPropertiesValue * (roundedLtv / 100));
      if (newValue > this.limit) {
        newValue = this.limit;
      }
      if (!this.verifyLvtBudget(newValue)) {
        this.setNadobudnutieAmount(this.lvtMax, false);
        this.valueCorrection = this.lvtMax;
        return;
      }
      this.setNadobudnutieAmount(newValue, false);
      this.valueCorrection = newValue;
    },
    calculateLvtMax (properties) {
      if (properties.length === 0) return;
      let value = 0;
      for (const property of properties) {
        value += property.hodnota
      }
      const estimatedLtv = Math.floor(value * 0.9);
      if (estimatedLtv > this.financedPropertiesValue) {
        this.lvtMax = this.financedPropertiesValue;
      } else {
        this.lvtMax = estimatedLtv;
      }
    },
    calculateFinancedPropertiesValue (properties) {
      this.financedPropertiesValue = 0;
      for (const property of properties) {
        this.financedPropertiesValue += property.hodnota
      }
      this.lvtMax = Math.floor(this.financedPropertiesValue * 0.9);
    },
    showConfirmationDialog () {
      this.$store.commit("appState/setSuccessMessage", {
        text: "Touto zmenou zmeníte účel hypotéky",
        type: "confirmation"
      });
    },
    checkForPurposeChange (value) {
      const currentMaxKey = Object.keys(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const currentMaxValue = Object.values(this.amounts).reduce((a, b) => this.amounts[a] > this.amounts[b] ? a : b);
      const newAmounts = {...this.amounts, suma_nadobudnutie: value};
      const newMaxKey = Object.keys(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      const newMaxValues = Object.values(newAmounts).reduce((a, b) => newAmounts[a] > newAmounts[b] ? a : b);
      if (currentMaxValue === 0 || newMaxValues === 0) return false;
      return currentMaxKey !== newMaxKey
    },
    askForUserPermission () {
      return new Promise((resolve) => {
        const int = setInterval(() => {
          const userConfirmation = this.$store.getters["appState/getUserConfirmation"];
          if (userConfirmation === true) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(true);
          } else if (userConfirmation === false) {
            this.$store.commit("appState/setUserConfirmation", null);
            clearInterval(int);
            resolve(false);
          }
        }, 100);
      });
    },
    async setNadobudnutieAmount (value, userInput) {
      if (!this.verifyLvtBudget(value)) {
        this.valueCorrection = this.lvtMax;
        this.$store.commit("mortgageDetailState/setAmount", {
          type: 'suma_nadobudnutie',
          value: this.lvtMax,
        });
        return;
      }
      this.value = value;
      const latestValue = this.amounts.suma_nadobudnutie;
      if (userInput) {
        const willChangePurpose = this.checkForPurposeChange(value);
        if (willChangePurpose) {
          this.showConfirmationDialog();
          if (!(await this.askForUserPermission())) {
            this.valueCorrection = latestValue;
            return;
          }
        }
      }
      if (latestValue === value) return;
      this.$store.commit("mortgageDetailState/setAmount", {
        type: 'suma_nadobudnutie',
        value: value,
      });
      const mortgageObject = this.createObjectToHistory();
      this.$store.dispatch("mortgageDetailState/patchMortgage", mortgageObject);
      this.$store.dispatch("mortgageDetailState/fetchOffers", this.mortgage.id);
    },
    createObjectToHistory () {
      const currentHistory = this.mortgage.offers_filter_history;
      const applicationData = this.$store.getters["mortgageDetailState/getOffersData"];
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let newHistoryObject = {
        date: new Date().toISOString(),
        fix: applicationData.fix ? applicationData.fix : 3,
        suma_bezucel: amounts.suma_bezucel ? amounts.suma_bezucel : 0,
        suma_nadobudnutie: amounts.suma_nadobudnutie ? amounts.suma_nadobudnutie : 0,
        suma_refinancovanie: amounts.suma_refinancovanie ? amounts.suma_refinancovanie : 0
      };
      if (!currentHistory) {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              broker: {
                lastOffersSetup: null,
                history: [newHistoryObject],
              },
              sent: [],
              client: [],
            }
          },
        }
      } else {
        return {
          id: this.mortgage.id,
          object: {
            offers_filter_history: {
              sent: currentHistory.sent,
              client: currentHistory.client,
              broker: {
                lastOffersSetup: currentHistory.broker?.lastOffersSetup ? currentHistory.broker.lastOffersSetup : null,
                history:
                  currentHistory.broker.history ? [newHistoryObject, ...currentHistory.broker.history] : Array.isArray(currentHistory.broker) && currentHistory.broker?.length !== 0 ? [newHistoryObject, ...currentHistory.broker] : [newHistoryObject],
              }
            }
          }
        }
      }
    },
    checkIfLimitIsEnough (maxLimit) {
      const amounts = this.$store.getters["mortgageDetailState/getAmounts"];
      let currentAmount = 0;
      for (const amount of Object.values(amounts)) {
        currentAmount += amount;
      }
      let excessAmount = currentAmount - maxLimit;
      return excessAmount <= 0;
    },
    setMaximumAmount () {
      let limit = this.totalLimit;
      for (const [key, amount] of Object.entries(this.amounts)) {
        if (key === "suma_nadobudnutie") continue;
        limit = limit - amount;
      }
      if (limit < 0) {
        this.limit = this.totalLimit;
        if (this.loanPurpose === "nadobudnutie") {
          this.$store.commit("mortgageDetailState/setAmounts", {
            suma_nadobudnutie: this.limit,
            suma_refinancovanie: 0,
            suma_bezucel: 0,
          });
        }
      } else {
        if (this.checkLtv && this.lvtMax < limit) {
          this.limit = this.lvtMax;
          if (this.lvtMax < this.amounts.suma_nadobudnutie) {
            this.valueCorrection = this.lvtMax;
            this.$store.commit("mortgageDetailState/setAmount", {
              type: 'suma_nadobudnutie',
              value: this.lvtMax,
            });
          }
        } else {
          this.limit = limit;
        }
      }
    },
    remove (idOfRemoved) {
      const data = {
        propertyId: idOfRemoved,
        mortgageId: this.mortgage.id,
        propertyLabel: "Financovaná",
        propertyType: "financedPropertiesIds",
        object: {
          financovane_nehnutelnosti:
            this.financedPropertiesIds.filter((id) => id !== idOfRemoved),
        }
      };
      this.$store.dispatch("mortgageDetailState/removeProperty", data);
    },
  }
};
</script>

<style lang="scss" scoped>
.closebtn-broker-app.pointer.edit{
  right:20px;
}
</style>
